import { Delete, Edit, Search, Tune, Visibility } from '@mui/icons-material'
import { Avatar, Box, Chip, Container, Grid, IconButton, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { dummyData } from '../../constant';
import { grey } from '@mui/material/colors';
import OrderDetails from '../../components/Order/Details/OrderDetails';
import ResBg from '../../assets/res_bg.jpg'

const Orders = () => {
    const [pageSize, setPageSize] = useState(10)
    const orderFilter = ['All', 'Ordered', 'Ongoing', 'Ready', 'Complete', 'Cancelled']

    const columns = [
        {field: 'user', headerName: 'Customer', flex: 1.2, renderCell: (params) => {
            return (
                <Stack direction={'row'} gap={'10px'}>
                    <Avatar />
                    <div>
                        <Typography noWrap sx={{fontWeight: 600}}>Daniel</Typography>
                        <Typography variant='body2' color={'GrayText'}>#{params?.row?.orderNumber}</Typography>
                    </div>
                </Stack>
            )
        }}, 
        {field: 'total', headerName: 'Amount', flex: 1, renderCell: (params) =>  <Typography>{params.value} €</Typography>}, 
        {field: 'createdAt', headerName: 'Date & Time',flex: 1.2, renderCell: (params) => { return `${new Date(params.value).toDateString()} at ${new Date(params.value).toLocaleTimeString()}`} },
        {field: 'status', headerName:' Status', flex: 1, renderCell: (params) => { return <Chip disabled label={params.value} />} },
        {field: 'id', headerName:' Action',flex: .7, renderCell: (params) => { 
            return <Stack direction={'row'} gap={'.4rem'}>
                    <IconButton onClick={()=> {}} ><Edit fontSize='small' /></IconButton> 
                    <IconButton onClick={()=> {}} ><Delete fontSize='small' /></IconButton> 
                </Stack>
        }}
    ]



  return (
    <>
    {/* banner */}
    <Box height={'10rem'} sx={{
                backgroundImage: `linear-gradient(180deg, rgba(0,0,0, 50%), rgba(0,0,0, 60%)), url(${ResBg})`, 
                backgroundSize: 'cover', backgroundPosition: 'center', color: '#fff', 
                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'
            }} />
    <Box sx={{padding: '6rem 0', bgcolor: grey[100]}}>
        <Container maxWidth='xl'>
            <Grid container spacing={5}>
                <Grid item sm={8.5}>
                    <Stack direction={'row'} gap={'2rem'} mb={3}>
                        <TextField fullWidth variant='outlined' InputProps={{
                            startAdornment: <InputAdornment position='start'><Search fontSize='small' /></InputAdornment>,
                            endAdornment: <InputAdornment position='end'>
                            <IconButton><Tune fontSize='small' /></IconButton></InputAdornment>
                        }} />
                        <TextField label='Categories' select sx={{width: '15rem'}} defaultValue={'all'} >
                            {
                                orderFilter?.map((el, index) => {
                                    return <MenuItem key={index} value={el.toLowerCase()}>{el}</MenuItem>
                                })
                            } 
                        </TextField>
                    </Stack>

                    <DataGrid sx={{bgcolor: '#fff'}} autoHeight isRowSelectable={(GridRowParams)=> false}
                        pagination rows={dummyData} rowsPerPageOptions={[10, 15, 20]}
                        rowHeight={90} columns={columns} headerHeight={80}
                        pageSize={pageSize} checkboxSelection
                        onPageSizeChange={(newSize)=> setPageSize(newSize)}
                    />
                    
                </Grid>
                <Grid item sm={3.5}>
                    <OrderDetails />
                </Grid>
            </Grid>
        </Container>
    </Box>
    </>
  )
}

export default Orders