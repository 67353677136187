import React, { useEffect, useState } from "react";
import PageHeader from "../Header/PageHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActions, Container, Grid } from "@mui/material";
import { getUserNotifications } from "../../services/services";
import { connect, useSelector } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationDetails from "./NotificationDetails/index.notificationDetails";

function Notifications() {
  const notifications = useSelector((state) => state.resto.notifications);

  const [preview, setPreview] = useState();

  return (
    <>
      <PageHeader
        page={"Notifications"}
        type={1}
        link={"/account/notifications"}
      />
      <Box
        variant="div"
        height={500}
        overflow={"scroll"}
        sx={{
          width: "100vw",
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={7}>
            <Box
              variant="div"
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              gap={3}
              my={3}
            >
              {notifications.map((notification, index) => (
                <Card
                  key={index}
                  sx={{
                    maxWidth: 500,
                    marginInline: "auto",
                    opacity: notification?.isRead ? 0.6 : 1,
                  }}
                >
                  <CardContent>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      sx={{ width: "100%" }}
                    >
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        flex={0.6}
                      >
                        {notification?.subject}
                      </Typography>
                      <Typography color={"text.secondary"} fontSize={12}>
                        {
                          new Date(notification?.updatedAt)
                            .toUTCString()
                            .split("GMT")[0]
                        }
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {notification?.body}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => setPreview(notification)}
                    >
                      See More
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </Box>
          </Grid>
          <Grid item sm={4.8}>
            <Box
              height={"26rem"}
              overflow={"scroll"}
              sx={{
                padding: 3,
                borderRadius: "15px",
                border: "1px solid lightgrey",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "sticky",
                top: 20,
              }}
            >
              {" "}
              {preview ? (
                <NotificationDetails data={preview} />
              ) : (
                <>
                  <NotificationsIcon
                    sx={{ fontSize: "10rem", color: "gray" }}
                  />
                  <Typography color={"GrayText"} mt={2}>
                    Select a notification to preview
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const mapStateToprops = (state) => state;

export default connect(mapStateToprops, { getUserNotifications })(
  Notifications
);
