import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ExtraItem from "../../Extras/ExtraItem";
import Counter from "../../Counter/Counter";
import GreyImg from "../../../assets/greyclick.jpg";
import { LoadingButton } from "@mui/lab";
import TRAY from "../../../assets/food-tray.gif";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ExpandLess, ExpandMore, SetMeal } from "@mui/icons-material";
import { restaurantAPI } from "../../../services/services";
import { ComboContext } from "../../../contexts/ComboMeal/comboMeal.context";

const ItemX = ({ d, constant }) => {
  const [qty, setQty] = useState(0);
  const dispatch = useDispatch();
  const { choices, setChoices, allowedQuantity, orderQuantity } =
    useContext(ComboContext);

  useEffect(() => {
    setChoices((prev) => {
      return { ...prev, [constant.menu.title + constant.menu.index]: [] };
    });
    setQty(0);
  }, [orderQuantity]);

  const addQty = () => {
    if (
      choices[constant.menu.title + constant.menu.index].length ===
      allowedQuantity * orderQuantity
    ) {
      return dispatch({ type: "ERROR", payload: "Cannot add more please" });
    }
    setQty((prev) => prev + 1);
    setChoices((prev) => {
      return {
        ...prev,
        [constant.menu.title + constant.menu.index]: [
          ...prev[constant.menu.title + constant.menu.index],
          d?.title,
        ],
      };
      // return [...prev, d?.title];
    });
  };

  const removeQty = () => {
    if (qty === 0) {
      return;
    } else {
      setQty((prev) => prev - 1);
      setChoices((prev) => {
        const isElement = (element) => element === d?.title;
        const newChoices = prev[
          constant.menu.title + constant.menu.index
        ].toSpliced(
          prev[constant.menu.title + constant.menu.index].findLastIndex(
            isElement
          ),
          1
        );
        // console.log(newChoices);
        return {
          ...prev,
          [constant.menu.title + constant.menu.index]: newChoices,
        };
      });
    }
  };

  return (
    <ListItem
      // disabled={choices.length === (allowedQuantity*orderQuantity)}
      sx={{ pl: 4 }}
      secondaryAction={<Counter count={qty} add={addQty} remove={removeQty} />}
    >
      <ListItemText primary={d?.title} />
    </ListItem>
  );
};

const MenuItemsUnderMenu = ({ menu, menuIndex }) => {
  const [items, setItems] = useState([]);
  const [open, setOpen] = React.useState(true);
  const { choices, setChoices, allowedQuantity, orderQuantity } =
    useContext(ComboContext);

  useEffect(() => {
    const fetchItems = async () => {
      const x = await restaurantAPI.getDishesForMenu(menu?._id);

      setItems(x?.data);
    };

    fetchItems();
  }, [menu]);

  const handleClick = () => {
    setOpen(!open);
  };

  const labelId = `checkbox-list-label-${menuIndex}`;

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary={menu?.title + " (Click to select)"}
          secondary={`You can select ${allowedQuantity * orderQuantity} items`}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items?.map((d) => (
            <ItemX
              d={d}
              constant={{
                qty: menu?.quantity,
                menu: {
                  title: menu?.title,
                  index: menuIndex,
                },
              }}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const MealDetailItem = ({ meal, addedToCart }) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const { slug } = useParams();
  const cart = useSelector((state) => state.cart);
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [load, setLoad] = useState(false);
  const [qty, setQty] = useState(1);
  let [addEx, setAddEx] = useState([]);
  const [choices, setChoices] = useState({});

  const { title, image, description, extras, price } = meal;
  const { discount, delivery } = meal;

  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    if (meal && price > 0) {
      discount?.isRunning && false
        ? (() => {
            setUnitPrice(
              Number((price * discount?.percentage) / 10.0).toFixed(2)
            );
            setTotal(Number((price * discount?.percentage) / 10.0).toFixed(2));
          })()
        : (() => {
            setUnitPrice(Number(price));
            setTotal(Number(price));
          })();
      setAddEx([]);
    }

    setQty(1);
    // eslint-disable-next-line
  }, [meal]);

  useEffect(() => {
    setAddEx(addEx);
  }, [addEx, total]);

  const addExtra = async (ext) => {
    const totalAmt = await [ext]?.reduce(
      (accumulator, currentVal) => accumulator + currentVal?.price,
      total
    );
    const totalAmtRd = Number(totalAmt.toFixed(2));
    setTotal(totalAmtRd);
    setAddEx((prev) => [...prev, ext]);
  };

  const removeExtra = async (ext) => {
    // const filterExtra = addEx?.filter(
    //   (el) => el?.text.toLowerCase() !== ext?.text?.toLowerCase()
    // );
    const totalAmt = await [ext]?.reduce(
      (accumulator, currentVal) => accumulator - currentVal?.price,
      total
    );
    const totalAmtRd = Number(totalAmt.toFixed(1));
    setTotal(totalAmtRd);
    const elToRemoveIndex = addEx?.findIndex((el) => el.id === ext.id);
    const extraFiltered = addEx.toSpliced(elToRemoveIndex, 1);
    setAddEx(extraFiltered);
  };

  const addQty = () => {
    setQty((prev) => {
      setTotal((prev) => prev + Number(unitPrice));
      return prev + 1;
    });
  };
  const removeQty = () => {
    if (qty === 1) {
      return;
    } else {
      setQty((prev) => {
        setTotal((prev) => prev - Number(unitPrice));
        return prev - 1;
      });
    }
  };

  const addToCart = () => {
    if (!currentUser) {
      // const callBackUrl = encodeURIComponent(path);
      dispatch({ type: "ERROR", payload: "Login to add to cart" });
      navigate(`/login`);
      return;
    }

    setLoad(true);
    setTimeout(() => {
      if (cart.items.length > 0 && cart?.items[0]?.restaurant !== slug) {
        dispatch({
          type: "ERROR",
          payload: "Cannot add meal from another restaurant",
        });
      } else {
        dispatch({
          type: "ADDTOCART",
          payload: {
            ...meal,
            restaurant: slug,
            quantity: qty,
            total: meal?.price * qty,
            userSelections: choices,
          },
        });
        dispatch({ type: "SUCCESS", payload: "Meal added to cart" });
        setAddEx([]);
        setQty(1);

        addedToCart();
      }
      setLoad(false);
    }, 500);
  };

  return (
    <>
      <Box
        height={"12rem"}
        bgcolor={"#eee"}
        borderRadius={"15px"}
        mb={3}
        padding={"1rem"}
        sx={{
          backgroundImage: `url(${image || GreyImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        {discount?.is_discount && (
          <Chip
            label={
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {discount?.discount_percentage}%
              </Typography>
            }
            size="small"
            sx={{
              bgcolor: "paper.main",
              boxShadow: "0 2px 10px rgba(0,0,0, 9%)",
            }}
          />
        )}
        <Chip
          label={
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {delivery?.time} mins
            </Typography>
          }
          size="small"
          sx={{
            bgcolor: "paper.main",
            boxShadow: "0 2px 10px rgba(0,0,0, 9%)",
            position: "absolute",
            bottom: 20,
            right: 15,
          }}
        />
      </Box>

      <Typography
        variant="h6"
        sx={{ textTransform: "capitalize", fontWeight: 500, mb: 0 }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontWeight: 300, mb: 2 }}
        color={"GrayText"}
        noWrap
      >
        {description}
      </Typography>
      <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
        <Typography
          color={false ? "GrayText" : "primary"}
          sx={{
            textDecoration: false && "line-through",
            fontWeight: 400,
          }}
        >
          {price} €
        </Typography>
        {false && (
          <Typography variant="h6" color={"primary"}>
            {price} €
          </Typography>
        )}
        <Box
          ml={"auto"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "20%",
            gap: "0.5rem",
            padding: "0.5rem",
          }}
        ></Box>
      </Stack>
      {meal?.items?.length > 0 && (
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Combo Items
            </ListSubheader>
          }
        >
          {meal?.items
            ?.filter((item) => Object.keys(item)[0] === "menuItem")
            ?.map((item) => (
              <ListItem secondaryAction={<>x{item?.quantity * qty}</>}>
                <ListItemText primary={item?.menuItem?.title} />
              </ListItem>
            ))}
          {meal?.items
            ?.filter((item) => Object.keys(item)[0] === "menu")
            ?.map((item, index) => (
              <ComboContext.Provider
                value={{
                  choices,
                  setChoices,
                  allowedQuantity: item?.quantity,
                  orderQuantity: qty,
                }}
              >
                <MenuItemsUnderMenu
                  menu={{ ...item?.menu, quantity: item?.quantity }}
                  menuIndex={index}
                />
              </ComboContext.Provider>
            ))}
        </List>
      )}
      {extras?.length > 0 && (
        <>
          <Divider sx={{ my: 3 }} />
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, mb: 1, fontSize: "1.2rem" }}
          >
            Extras
          </Typography>
          <Box
            sx={{
              maxHeight: "16rem",
              minHeight: "fit-content",
              overflowY: "scroll",
            }}
          >
            {extras?.map((el) => {
              return (
                <ExtraItem
                  key={el?._id}
                  text={el?.text}
                  price={el?.extras_price}
                  //   extras={addEx}
                  remove={(val) => removeExtra(val)}
                  add={(ext) => addExtra(ext)}
                  id={el?._id}
                />
              );
            })}
          </Box>
        </>
      )}
      <Divider sx={{ my: 3 }} />
      <Stack direction={"row"} gap={"1rem"}>
        <Counter count={qty} remove={removeQty} add={addQty} />
        <LoadingButton
          loading={load}
          variant="contained"
          color="primary"
          onClick={addToCart}
          disableElevation
          sx={{ height: "3rem" }}
          fullWidth
        >
          Add To Cart - {Number(total).toFixed(2)} €
        </LoadingButton>
      </Stack>

      {/* Loading Dishes */}
      <Dialog open={load} maxWidth="sm">
        <DialogContent sx={{ padding: "3rem 1rem", textAlign: "center" }}>
          <img src={TRAY} width={"15%"} alt="loading" />
          {/* <LinearProgress color='primary' variant='indeterminate' /> */}
          <Typography mt={0}>Add Meal to Cart. Please wait</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MealDetailItem;
