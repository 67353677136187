import { Box, Breadcrumbs, Container, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Pgimg from '../../assets/bg_footer.jpg'
import BreadImg from '../../assets/breadcrumb1.jpg'

const PageHeader = ({page, link, type}) => {


  return (
    <Box height={'15rem'} sx={{ backgroundImage: `url(${type ? BreadImg : Pgimg })`, backgroundPosition: 'center', backgroundSize: 'cover', display: 'flex', alignItems: 'center'}}>
        <Container>
        <Typography mb={1} textAlign={'center'} variant='h4' sx={{fontWeight: 600}}>{page}</Typography>
            <Breadcrumbs sx={{display: 'flex', justifyContent: 'center'}}>
                <Link to={'/'}>Home</Link>
                <Link to={link}>{page}</Link>
            </Breadcrumbs>
        </Container>
    </Box>
  )
}

export default PageHeader