import { Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";

const OrderItem = ({ data, onClick }) => {
  return (
    <>
      <Box
        onClick={onClick}
        padding={"1rem 1.5rem"}
        bgcolor={"#fff"}
        sx={{
          borderRadius: "10px",
          border: "1px solid #fff",
          boxShadow: "0 2px 10px rgba(0,0,0, 8%)",
          cursor: "pointer",
        }}
      >
        <Stack
          direction={"row"}
          flexWrap={'wrap-reverse'}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography>{data?.restaurant?.name}</Typography>
          <Typography sx={{ fontSize: "1rem" }} color={"primary"}>
            €{data?.total}
          </Typography>
        </Stack>
        {/* <Typography variant='body2' color={'GrayText'}>Order N. #{data.orderNumber}</Typography> */}
        {/* <Typography variant='body2' color={'GrayText'}>Order N#{data.orderNumber}</Typography> */}
        <Typography
          variant="body2"
          mb={2}
          color={"GrayText"}
          sx={{ fontSize: ".8rem" }}
        >
          {data?.dishes?.reduce((accumulator, currentObject) => {
            return (
              accumulator +
              currentObject?.quantity +
              currentObject?.extras.reduce(
                (accumulator, currentExtraObject) =>
                  accumulator + currentExtraObject?.qty,
                0
              )
            );
          }, 0)}{" "}
          Items
        </Typography>
        <Stack
          direction={"row"}
          flexWrap={'wrap'}
          gap={{xs: '1rem', lg: '0'}}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Chip
            color="primary"
            size="small"
            label={
              <Typography sx={{ fontSize: ".7rem", color: 'white' }}>{data?.status}</Typography>
            }
          />
          <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
            {
              new Date(data?.createdAt)
                .toUTCString()
                .split(",")[1]
                .split("GMT")[0]
            }
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default OrderItem;
