let environments = {};

environments.local = {
  envName: "local",
  baseUrl: "http://localhost:4000",
};

environments.staging = {
  envName: "staging",
  baseUrl: "https://clickso.fr",
};

environments.testing = {
  envName: "testing",
  baseUrl: "https://clickso.fr",
};

environments.production = {
  envName: "production",
  baseUrl: "http://localhost:5000",
};

let currentEnvironment =
  process.env.REACT_APP_NODE_ENV?.toLowerCase() || "local";

let environmentToExport =
  environments[currentEnvironment] || environments.testing;

export default environmentToExport;
