import { createTheme } from "@mui/material/styles";


export const theme = createTheme({
    typography: {
        fontFamily: 'Poppins',
        fontSize: 14,
        body1: {
            fontFamily: 'Poppins',
            fontWeight: 400,
        },
        body2: {
            fontFamily: 'Poppins',
            fontWeight: 400,
        },
        h4: {
            fontSize: '2rem'
        },
        h5: {
            fontSize: '1.8rem'
        },
        h6: {
            fontSize: '1.4rem'
        },
    },
    palette: {
        primary: {
            main: '#D0202A'
        },
        secondary: {
            main: '#D0202A'
        },
        tertiary: {
            main: '#3C8342'
        },
        text:{
            main: '#000'
        },
        paper: {
            main: '#fff'
        }
    },
})