import { Email, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { encryptData, isRemeberMe, loginUser, userAccountAPI } from "../../services/services";
import socket, { testConnection } from "../../services/socket";

const Login = (props) => {
  const { loginUser } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  const callbackUrl = searchParams.get("return");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userType, setUserType] = useState("buyer");
  const [rememberMe, setRememberMe] = useState(false);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const { email, username } = isRemeberMe();
    if (email) {
      setEmail(email);
      setRememberMe(true);
    }
    if (username) {
      setUsername(username);
      setRememberMe(true);
    }
  }, []);

  const onLoginSubmit = async () => {

    if (userType === "buyer" && rememberMe) {
      localStorage.setItem("cls_uem", JSON.stringify(email));
    }
  
    // Api Connect //
    const data =
      { email, password };

    setLoad(true);

    
    try {
      // const res = await loginUser(userType, data);
      const res = await userAccountAPI.preLogin(data).catch(e=>e)

      // console.log(res)

      if (res.success) {

        dispatch({ type: "SUCCESS", payload: "Verification Successful" });


        const encryptedData = encryptData(JSON.stringify({email}));
        localStorage.setItem("xyz", encryptedData);
        
        navigate("/account/verification?imperio=" + encryptedData);
        // navigate(callbackUrl || "/account/me");
        setLoad(false);
      } else {
        dispatch({ type: "ERROR", payload: res });

        setLoad(false);
      }
    } catch (err) {

      console.log(err)

      dispatch({type: "ERROR", payload: err || "An error has occurred"})
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "480px",
          bgcolor: "#fff",
          borderRadius: "8px",
          padding: {
            xs: "1rem",
            md: "4rem",
          },
          textAlign: "center",
          boxShadow: "0 10px 30px rgba(0,0,0, 12%)",
          "& > span": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "1rem",
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, mt: "0rem", mb: ".4rem" }}
        >
          Welcome Back !
        </Typography>
        <Typography variant="body1" mb="2rem" color={"GrayText"}>
          {/* Please enter your login details below */}
          Veuillez rentrez vos email et mot de passe!
        </Typography>
        <form onSubmit={onLoginSubmit}>
          {userType === "buyer" && (
            <TextField
              sx={{ mb: "1rem" }}
              fullWidth
              variant="outlined"
              label="Email"
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Email fontSize="small" sx={{ color: grey[400] }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
          {userType === "restaurant" && (
            <TextField
              sx={{ mb: "1rem" }}
              fullWidth
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              label="Username"
              type={"text"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Person fontSize="small" sx={{ color: grey[400] }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <TextField
            sx={{ mb: "1rem" }}
            fullWidth
            variant="outlined"
            label="Mot de Passe"
            type={show ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {show ? (
                    <IconButton onClick={() => setShow(false)}>
                      <Visibility fontSize="small" sx={{ color: grey[400] }} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setShow(true)}>
                      <VisibilityOff
                        fontSize="small"
                        sx={{ color: grey[400] }}
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Box 
            display='flex' 
            justifyContent='space-between'
            alignItems='center'>
            <FormControlLabel
              onChange={() => setRememberMe(!rememberMe)}
              control={<Checkbox checked={rememberMe} />}
              label={
                <Typography variant="body2" color={"GrayText"}>
                  {/* Remember Me */}
                  Se rappeler de moi !
                </Typography>
              }
            />
            <Link to={"/forgot-password"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "#000" }}
              >
                {/* Forgot Password? */}
                Mot de passe oublié?
              </Typography>
            </Link>
          </Box>
          <LoadingButton
            loading={load}
            type="submit"
            onClick={onLoginSubmit}
            disabled={!email || !password}
            disableElevation
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              color: "#fff",
              height: "3.5rem",
              mb: "2rem",
            }}
          >
            {/* Login To Account? */}
            Se connecter
          </LoadingButton>
        </form>
        <Typography textAlign={"center"} color={"GrayText"} mb="2rem">
          <span style={{ marginInline: "5px" }}>Pas de compte encore?</span>
          <Link to={"/register"}>
            {/* Register */}
            Créer un compte
          </Link>
        </Typography>
        {/* <Typography variant='body2' color={'GrayText'} textAlign='center'>By continuing, you  agree to our Terms of Use and Privacy Policy</Typography> */}
      </Box>
    </Box>
  );
};

const mapStateToprops = (state) => state;

export default connect(mapStateToprops, { loginUser })(Login);
