import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const SECRET_KEY = "N4YgYO2h+x8oTuGG8eTNHusGot7EE0J9bTm5vTAL5/4PSJNUVd+slv/vLWlBEbsi"

const encryptor = encryptTransform({
    secretKey: SECRET_KEY,
    onError: (error) => {
        console.log(error)
    }
})

const persistConfig = {
	key: 'root',
	storage,
    transforms: [encryptor]
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store);


const root = ReactDOM.createRoot(document.getElementById('root'));
  
  root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);