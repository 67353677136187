import React, { useEffect, useState } from "react";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { placeOrderX, userAccountAPI } from "../services/services";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { useDispatch, useSelector } from "react-redux";
import { notifyRestaurantForOrderPlaced } from "../services/socket";

const stripePromise = loadStripe(
  "pk_test_51MEvq4C582oYJ4LSsyY6FUi5JxtMdRXQHi64oTBsCk1fARYeobD2Nqg6et0g8AyUYIib40eguK3lFDubKpBjkf1000fr18kg41"
);

function AfterCheckoutForm() {
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const resto = useSelector((state)=>state.resto)
  const currentUser = useSelector((state)=>state.currentUser)
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret || clientSecret == null) {
      return;
    } else {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            setStatus("success");

            if (resto.orderSet) {
              const order = resto.orderToBePlaced;
              console.log(order)
    
              userAccountAPI.placeOrder({ payment: {intent: paymentIntent}, ...order })
                .then((response) => {
                  if(response.success) {
                    dispatch({type: 'SUCCESS', payload: 'Your order has been placed'})
                    dispatch({type: 'CLEAR_CART'})
                    dispatch({type: 'CLEAR ORDER THAT WAS PLACED'})
                    notifyRestaurantForOrderPlaced({...order, user: currentUser})
                  } else {
                    dispatch({type: 'ERROR', payload: 'Sorry, your order could not be placed'})
                  }
                })
                .catch((e) => {
                  console.log(e)
                  dispatch({ type: "ERROR", payload: e?.message })
                });


            } else {
              return
              // return dispatch({ type: "ERROR", payload: "No order specified or your order has been already placed !" });
            }
            break;
          case "processing":
            setMessage("Your payment is processing.");
            setStatus("processing");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }

        

      });
    }
  }, [stripe, resto.orderSet, resto.orderToBePlaced, dispatch, clientSecret]);

  return (
    <>
      {message && (
        <div
          id="payment-message"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBlock: "5rem",
          }}
        >
          <Card sx={{ width: "25rem" }}>
            <CardContent>
              <Typography
                // sx={{ fontWeight: 'bold' }}
                color="text.primary"
                variant="h5"
                gutterBottom
              >
                {message}
              </Typography>
              <Box sx={{ mb: 1.5 }}>
                {status === "success" && (
                  <CheckCircleOutlineIcon
                    style={{ fontSize: "6rem", color: "green" }}
                  />
                )}
                {status === "processing" && (
                  <PendingOutlinedIcon
                    style={{ fontSize: "6rem", color: "orange" }}
                  />
                )}
              </Box>
              <Typography variant="body2">
                {status === "success" && (
                  <span>Thank you for your purchase!</span>
                )}
                <br />
              </Typography>
            </CardContent>
            <CardActions>
              {/* <Button size="small">Learn More</Button> */}
            </CardActions>
          </Card>
        </div>
      )}
    </>
  );
}

export default function ResultsPage() {
  const [clientSecret, setClientSecret] = useState(
    new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )
  );

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {true && (
        <Elements options={options} stripe={stripePromise}>
          <AfterCheckoutForm />
        </Elements>
      )}
    </div>
  );
}
