import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import OrderMealItem from "../MealItem/OrderMealItem";

const OrderDetails = ({ data }) => {

  const subTotal = useMemo(()=>data?.items?.reduce((accumulator, currentValue) => {
    return accumulator + ((Number(currentValue['comboMeal']?.price ||  Number(currentValue['menuItem']?.price || 0)) * Number(currentValue?.quantity)))
  }, 0), [data])

  return (
    <Box
      height={"100%"}
      width={"100%"}
      sx={{
        padding: "1.5rem",
        borderRadius: "10px",
        bgcolor: "#fff",
        left: 0,
        right: 0,
        position: "relative",
        overflowY: 'scroll'
      }}
    >
      <Typography variant="h6">Order Details</Typography>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
          {data?.name}
        </Typography>

      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
          Date
        </Typography>
        <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
          {/* {new Date(data?.createdAt).toUTCString().split("GMT")[0]}{" "} */}
          {new Date(data?.createdAt).toUTCString().split(',')[1].split('GMT')[0]}

        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
          Payment
        </Typography>
        <Typography
          sx={{ fontSize: ".9rem" }}
          color={"GrayText"}
          textTransform={"uppercase"}
        >
          {data?.payment?.method}
        </Typography>
      </Stack>
      <Divider sx={{ my: "1.5rem" }} />
      {data?.items?.map((dish, index) =>
        <OrderMealItem key={index} data={dish} />
      )}
      <Divider sx={{ mt: "1.5rem" }} />
      <Box paddingBlock={1}>
        <Typography variant="h6" sx={{ fontSize: "1.3rem" }}>
          Summary
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
            SubTotal
          </Typography>
          <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
            {Number(subTotal).toFixed(2)} €
          </Typography>
        </Stack>
        {/* <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
            Discount
          </Typography>
          <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
            -12.5 €
          </Typography>
        </Stack> */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
            Delivery Fee
          </Typography>
          <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
            {Number(data?.fees?.delivery).toFixed(2)} €
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
            Service Fee
          </Typography>
          <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
            {Number(data?.fees?.service).toFixed(2)} €
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
            TOTAL
          </Typography>
          <Typography
            sx={{ fontSize: ".9rem", fontWeight: 600 }}
            color={"primary"}
          >
            {Number(data?.total).toFixed(2)} €
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default OrderDetails;
