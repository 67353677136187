import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getUserNotifications,
  readNotification,
} from "../../../services/services";
import { useDispatch } from "react-redux";

function NotificationDetails({ data }) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    readNotification(data._id).then((response) => {
      if (response.status === "success") {
        setLoading(false);
        getUserNotifications()
          .then((response) => {
            if (response.status === "success") {
              dispatch({
                type: "ALL_NOTIFICATIONS",
                payload: response.userNotifications,
              });
            } else {
              dispatch({
                type: "ALL_NOTIFICATIONS",
                payload: "Failed to fetch",
              });
            }
          })
          .catch((e) => {
            dispatch({ type: "ALL_NOTIFICATIONS", payload: "Failed to fetch" });
          });
      }
    });
  }, [dispatch]);

  return (
    <Box
      height={"30rem"}
      width={"100%"}
      sx={{
        paddingInline: "1.5rem",
        borderRadius: "10px",
        bgcolor: "#fff",
        position: "relative",
      }}
    >
      <Typography variant="h6">Notification Details</Typography>
      <Stack
        mt={3}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
          Date
        </Typography>
        <Typography
          sx={{ fontSize: ".9rem" }}
          width={"60%"}
          color={"GrayText"}
          textAlign={"right"}
        >
          {new Date(data?.createdAt).toUTCString().split('GMT')}
        </Typography>
      </Stack>
      <Stack
        mt={3}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
          Subject
        </Typography>
        <Typography
          sx={{ fontSize: "1rem" }}
          width={"60%"}
          color={"GrayText"}
          textAlign={"right"}
        >
          {data?.subject}
        </Typography>
      </Stack>
      <Stack
        mt={3}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
          Message
        </Typography>
        <Typography
          sx={{ fontSize: ".9rem" }}
          width={"60%"}
          color={"GrayText"}
          textAlign={"right"}
        >
          {data?.body}
        </Typography>
      </Stack>
    </Box>
  );
}

export default NotificationDetails;
