import {
  Avatar,
  Box,
  Chip,
  Collapse,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const OrderMealItem = ({ data }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [label, setLabel] = useState("");

  useEffect(() => {
    setLabel(() => {
      return Object.prototype.hasOwnProperty.call(data, "comboMeal")
        ? "comboMeal"
        : "menuItem";
    });
  }, [data]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack
      direction={"row"}
      gap={"1rem"}
      sx={{
        bgcolor: "#fff",
        padding: "10px",
        borderRadius: "10px",
        my: "1rem",
        border: "1px solid lightgrey",
      }}
    >
      <Avatar
        sx={{ width: "4.5rem", height: "4.5rem", borderRadius: "10px" }}
        src={data[label]?.image}
      />
      <Box width={"100%"}>
        <Typography noWrap sx={{ width: "100%", fontSize: ".9rem" }}>
          {data[label]?.title}
        </Typography>
        <Stack direction={"row"} gap={".6rem"} alignItems={"center"}>
          {/* <Typography color={'GrayText'} sx={{textDecoration: 'line-through'}}>€20</Typography> */}
          <Typography color={"primary"} sx={{ fontWeight: 500 }}>
            €{data[label]?.price}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          gap={"1rem"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="body2" color={"GrayText"}>
            Qty - {data?.quantity}
          </Typography>
          {data[label]?.items?.length > 0 && (
            <Chip
              size="small"
              color="primary"
              label={
                <Typography sx={{ fontSize: ".7rem", color: "white" }}>
                  See Details
                </Typography>
              }
            />
          )}
        </Stack>
        {data[label]?.items?.length > 0 && (
          <>
            <Box display={"flex"}>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Box>
                <Typography paragraph>Details:</Typography>
                {data[label]?.items?.map((extra, index) => (
                  <Stack
                    key={index}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{ fontSize: "1rem" }}
                      color={"GrayText"}
                      textTransform={"capitalize"}
                    >
                      {extra["menu"]?.title}
                      {extra["menuItem"]?.title}
                    </Typography>
                    {/* <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
                      {Number(
                        Number(extra?.extras_price) * Number(extra?.qty)
                      ).toFixed(2)}{" "}
                      €
                    </Typography> */}
                    <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
                      x{extra?.quantity}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Collapse>
          </>
        )}
      </Box>
    </Stack>
  );
};

export default OrderMealItem;
