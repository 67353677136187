import {
  Fastfood,
  Favorite,
  KeyboardArrowLeft,
  Search,
  Star,
  ViewList,
  ViewModule,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ResBg from "../../../../assets/res_bg.jpg";
import TRAY from "../../../../assets/food-tray.gif";
import MenuImage from "../../../../assets/menus.png";
import MealItem from "../../../../components/Meal/Item/MealItem";
import { grey } from "@mui/material/colors";
import MealDetailItem from "../../../../components/Meal/DetailItem/MealDetailItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { base, restaurantAPI, userAccountAPI } from "../../../../services/services";
import Loader from "../../../../components/Loader/Loader";
import MealBg from "../../../../assets/greyclick.jpg";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ComboContext } from "../../../../contexts/ComboMeal/comboMeal.context";
import StarIcon from '@mui/icons-material/Star';
import { LoadingButton } from "@mui/lab";
import { labels } from "../../../../constant";

const RestaurantDetail = ({ id }) => {

  // REDUX STORE
  const currentUser = useSelector(state=>state.currentUser)
  
  // LOADERS
  const [submittingRating, setSubmittingRating] = useState(false)
  const [meal, setMeal] = useState();
  const [comboMeal, setComboMeal] = useState();

  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [menus, setMenus] = useState([]);
  const [dishes, setDishes] = useState({});
  const [combos, setCombos] = useState({});
  const [show, setShow] = useState(false);

  // MODALS
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [open, setOpen] = useState(false);

  const [change, setChange] = useState(false);

  const [ratingValue, setRatingValue] = useState(0);
  const [hoverRating, setHoverRating] = useState(-1)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (slug && slug !== "undefined") {
        try {
          const response = await restaurantAPI.getById(slug);
          await Promise.all([
            restaurantAPI.getMenuForRestaurant(slug),
            restaurantAPI.getComboForRestaurant(slug),
          ]).then((responses) => {
            setMenus(responses[0]?.data);
            setCombos({ const: responses[1].data, values: responses[1].data });
          });

          if (response?.data?.workingSchedule?.length <= 0) {
            dispatch({ type: "ERROR", payload: "Restaurant Temporary Closed" });
            navigate("/restaurants");
            return;
          }
          setData(response?.data);
          setLoading(false);
        } catch (error) {
          dispatch({ type: "ERROR", payload: error?.message });
          return navigate("/restaurants");
        }
      } else {
        return navigate("/restaurants");
      }
    };

    fetchData();
  }, [slug, navigate, dispatch]);

  // HANDLERS

  const handleRatingValueChange = (e) => {
    setRatingValue(e.target.value);
  };


  // EXTRA FUNCTIONS
  const stepBack = (e) => {
    e.preventDefault();
    setShow(false);
    // setMenus(data?.menus);
    setMeal();
  };

  const previewMeal = (meal) => {
    if (true) {
      setMeal(meal);
      setChange(true);
      dispatch({ type: "SUCCESS", payload: "Added to preview" });
    } else {
      dispatch({
        type: "ERROR",
        payload: "Restaurant Not Open, Cannot preview meal",
      });
    }
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  // SEARCH FUNCTIONS
  const onComboSearch = (val) => {
    if (val.length <= 0) {
      setCombos({ ...combos, values: combos?.const });
      return;
    }

    const filtered = [...combos?.const]?.filter((el) =>
      el?.title?.toLowerCase()?.includes(val.toLowerCase())
    );
    setCombos({ ...combos, values: filtered });
  };

  const onMealSearch = (val) => {
    if (val.length <= 0) {
      setDishes({ ...dishes, dishes: dishes?.constDishes });
      return;
    }

    const filtered = [...dishes?.constDishes]?.filter((el) =>
      el?.title?.toLowerCase()?.includes(val.toLowerCase())
    );
    setDishes({ ...dishes, dishes: filtered });
  };

  const onmenuSearch = (val) => {
    if (val === "") {
      setMenus(data?.menus);
      return;
    }
    const filtered = [...data?.menus]?.filter((el) =>
      el?.name?.toLowerCase()?.includes(val)
    );
    setMenus(filtered);
  };

  // FETCHERS
  const getMenuDishes = async (id) => {
    try {
      setOpen(true);
      // const { data: res } = await base.get(`/api/app/v1/menu/${id}/dishes`);
      const { data: res } = await restaurantAPI.getDishesForMenu(id);
      setDishes({ constDishes: res, dishes: res });
      setOpen(false);
      setShow(true);
    } catch (error) {
      setOpen(false);
    }
  };

  // SUMBIT FUNCTIONS
  
  const onSubmitRating = async () => {
    if(!currentUser) return dispatch({type: 'ERROR', payload: 'Log in before rating a restaurant'})
    if(ratingValue === null) return dispatch({type: "ERROR", payload: 'Provide a rate before submitting'})

    // Change the state of the loader
    setSubmittingRating(true);

    const payload = {
      userId: currentUser._id,
      rating: ratingValue
    }

    try {

      const response = await userAccountAPI.submitReview(slug, payload)
      dispatch({type: "SUCCESS", payload: response?.message})
      setOpenRatingModal(false)
    } catch (e) {
      console.log(e)
      dispatch({type: "ERROR", payload: e})

    }

    finally {
      setSubmittingRating(false);
    }
 
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box className="oswald-categoryCard">
          <Box
            height={"15rem"}
            sx={{
              backgroundImage: `linear-gradient(180deg, rgba(0,0,0, 50%), rgba(0,0,0, 60%)), url(${
                data?.images?.profile || ResBg
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "#fff",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  fontSize: {
                    xs: "1.5rem",
                    sm: "2rem",
                  },
                }}
              >
                {data?.name?.toUpperCase()}
              </Typography>
              <Typography
                variant="body1"
                paddingInline={"1rem"}
                sx={{
                  color: "white",
                  fontSize: {
                    xs: ".8rem",
                    sm: "1rem",
                  },
                }}
              >
                {_.startCase(data?.description)}
              </Typography>
              <Typography
                variant="body1"
                paddingInline={"1rem"}
                sx={{
                  color: "white",
                  fontSize: {
                    xs: ".8rem",
                    sm: "1rem",
                  },
                }}
              >
                {`${data?.address?.city}, ${data?.address?.street} - ${data?.address?.postalCode}`}
              </Typography>
              <Grid
                container
                spacing={2}
                mt={1}
                sx={{
                  width: "fit-content",
                  minWidth: "230px",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Star color="secondary" sx={{ fontSize: "2.3rem" }} />
                  <Typography
                    variant="h4"
                    display="block"
                    sx={{
                      color: "white",
                    }}
                  >
                    {data?.reviewStats?.avgRating}
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="left">
                  <Typography
                    variant="caption"
                    display="block"
                    sx={{
                      color: "white",
                      fontSize: "1.1rem",
                    }}
                  >
                    {labels[data?.reviewStats?.avgRating]}
                  </Typography>
                  <Typography
                    variant="p"
                    display="block"
                    sx={{
                      color: "white",
                      fontSize: {
                        xs: ".8rem",
                      },
                      fontStyle: "italic",
                    }}
                  >
                    {data?.reviewStats?.reviewList?.length} Review(s)
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography variant="h5">JOUR D'OUVERTURE</Typography>
              {data?.workingSchedule?.map((schedule) => (
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption">{schedule?.day}</Typography>
                  <Box display="flex">
                    {schedule?.hours?.map((hour) => (
                      <Typography variant="caption">{hour}|</Typography>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
            {currentUser && <Box display="flex" flexWrap="wrap" gap={2} alignItems="start">
              <Button
                variant="contained"
                onClick={() => setOpenRatingModal(true)}
                startIcon={<Star />}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  textTransform: "none",
                  margin: "0 auto",
                  ":hover": {
                    color: "white",
                  },
                }}
              >
                Rate us
              </Button>
              <Button
                variant="contained"
                onClick={() => {}}
                startIcon={<Favorite />}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  textTransform: "none",
                  margin: "0 auto",
                  ":hover": {
                    color: "white",
                  },
                }}
              >
                Add to Favourites
              </Button>
            </Box>}
          </Box>

          <Box
            sx={{
              my: "1rem",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              overflowX: "scroll",
            }}
          >
            {menus.map((menu) => (
              <Chip
                sx={{ textTransform: "capitalize" }}
                label={menu?.title}
                variant="outlined"
                onClick={(e) => getMenuDishes(menu?._id)}
              />
            ))}
          </Box>
          {show ? (
            <Box padding={"6rem 0"}>
              <Container maxWidth="lg">
                <Grid container columnSpacing={5}>
                  <Grid item sm={8.5}>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      gap={"2rem"}
                      sx={{ mb: "3rem" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={stepBack}
                        sx={{ width: "10rem" }}
                        disableRipple
                        startIcon={<KeyboardArrowLeft fontSize="small" />}
                      >
                        Go Back
                      </Button>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="search"
                        placeholder="Search for meals"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => onMealSearch(e.target.value)}
                      />
                      {/* <Stack
                        direction={"row"}
                        gap={".5rem"}
                        alignItems={"center"}
                      >
                        <IconButton
                          sx={{ bgcolor: grey[200], borderRadius: "5px" }}
                        >
                          <ViewList />
                        </IconButton>
                        <IconButton
                          sx={{ bgcolor: grey[200], borderRadius: "5px" }}
                        >
                          <ViewModule />
                        </IconButton>
                      </Stack> */}
                    </Stack>

                    <Grid container spacing={3}>
                      {dishes?.dishes?.map((el, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <MealItem
                              name={el?.title}
                              image={el?.image}
                              price={el?.price}
                              description={el?.description}
                              onClick={() => previewMeal(el)}
                              discount={data?.discount}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3.5}
                    sx={{
                      marginTop: {
                        xs: "1rem",
                        md: "0",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid lightgrey",
                        height: "fit-content",
                        padding: "15px",
                        position: "sticky",
                        top: 10,
                      }}
                    >
                      {meal ? (
                        <MealDetailItem meal={meal} addedToCart={setMeal} />
                      ) : (
                        <Box
                          height={"30rem"}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Fastfood
                            sx={{ fontSize: "6rem", color: grey[300] }}
                          />
                          <Typography color={"GrayText"} mt={2}>
                            No Meal Selected
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          ) : (
            <Box padding={"6rem 0"}>
              <Container maxWidth="lg">
                <Stack
                  mb={"1.5rem"}
                  direction={{ xs: "column", md: "row" }}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h3">Our Menus</Typography>
                  <TextField
                    size="small"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "40%",
                      },
                    }}
                    onChange={(e) => {}}
                    variant="outlined"
                    type="search"
                    placeholder="Search for menu"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Grid container spacing={3}>
                  {menus?.length > 0 ? (
                    menus?.map((el) => {
                      return (
                        <Grid
                          key={el?._id}
                          item
                          sm={6}
                          md={3}
                          sx={{
                            width: {
                              xs: "100% !important",
                            },
                          }}
                        >
                          <Box
                            p={2}
                            // border={"1px solid lightgrey"}
                            borderRadius={"15px"}
                            onClick={() => getMenuDishes(el?._id)}
                            sx={{
                              textTransform: "capitalize",
                              transition: "all .3s ease-in",
                              ":hover": {
                                transform: "translateY(15px)",
                                // backgroundColor: "#e17245 !important",
                                backgroundColor: "#e17245b8 !important",
                                color: "white",
                                cursor: "pointer",
                                border: (theme) =>
                                  `1px solid ${theme.palette.secondary.main}`,
                                transition: "all .5s ease-in-out",
                              },
                            }}
                          >
                            <Box
                              height={"12rem"}
                              bgcolor={"#eee"}
                              borderRadius={"15px"}
                              mb={3}
                              padding={"1rem"}
                              sx={{
                                backgroundImage: `url(${
                                  el?.image ? el?.image : MealBg
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                position: "relative",
                              }}
                            >
                              {data?.discount?.is_discount && (
                                <Chip
                                  label={
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 500 }}
                                    >
                                      {data?.discount?.discount_percentage}%
                                    </Typography>
                                  }
                                  size="small"
                                  sx={{
                                    bgcolor: "paper.main",
                                    boxShadow: "0 2px 10px rgba(0,0,0, 9%)",
                                  }}
                                />
                              )}
                              {/* <IconButton size='small' sx={{position: 'absolute', right: '5%', top: '4%', bgcolor: '#fff'}}><FavoriteBorder fontSize='small'/></IconButton> */}
                            </Box>
                            <Stack textAlign={"center"}>
                              <Typography
                                variant="h5"
                                noWrap
                                sx={{
                                  fontWeight: 500,
                                  mb: 0.5,
                                  // fontSize: "1.1rem",
                                }}
                              >
                                {el?.title}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 300, mb: 2 }}
                                color={"GrayText"}
                                noWrap
                              >
                                {el?.description}
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      );
                    })
                  ) : (
                    <Box textAlign={"center"} padding={"4rem"} width={"100%"}>
                      <img src={MenuImage} width={"12%"} alt="no_menu" />
                      <Typography variant="h6" mt={3}>
                        No Menus
                      </Typography>
                      <Typography variant="body1" color={"GrayText"}>
                        Sorry we are yet to add our menus{" "}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Container>
              <Container maxWidth="lg" sx={{ marginTop: "3rem" }}>
                <Stack
                  mb={"1.5rem"}
                  direction={{ xs: "column", md: "row" }}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h3">Nos Combos</Typography>
                  <TextField
                    size="small"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "40%",
                      },
                    }}
                    onChange={(e) => onComboSearch(e.target.value)}
                    variant="outlined"
                    type="search"
                    placeholder="Search for Combo"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Grid container spacing={2}>
                  <Grid item sm={8.5}>
                    <Grid container spacing={3}>
                      {combos.values?.length > 0 ? (
                        combos.values?.map((el) => {
                          return (
                            <Grid
                              key={el?._id}
                              item
                              sm={6}
                              md={4}
                              sx={{
                                width: {
                                  xs: "100% !important",
                                },
                              }}
                            >
                              <Box
                                p={2}
                                // border={"1px solid lightgrey"}
                                borderRadius={"15px"}
                                onClick={() => setComboMeal(el)}
                                sx={{
                                  height: "100%",
                                  transition: "all .3s ease-in",
                                  ":hover": {
                                    transform: "translateY(15px)",
                                    // backgroundColor: "#e17245 !important",
                                    backgroundColor: "#e17245b8 !important",
                                    color: "white",
                                    cursor: "pointer",
                                    border: (theme) =>
                                      `1px solid ${theme.palette.secondary.main}`,
                                    transition: "all .5s ease-in-out",
                                  },
                                }}
                              >
                                <Box
                                  height={"12rem"}
                                  bgcolor={"#eee"}
                                  borderRadius={"15px"}
                                  mb={3}
                                  padding={"1rem"}
                                  sx={{
                                    backgroundImage: `url(${
                                      el?.image ? el?.image : MealBg
                                    })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    position: "relative",
                                  }}
                                >
                                  {data?.discount?.is_discount && (
                                    <Chip
                                      label={
                                        <Typography
                                          variant="body2"
                                          sx={{ fontWeight: 500 }}
                                        >
                                          {data?.discount?.discount_percentage}%
                                        </Typography>
                                      }
                                      size="small"
                                      sx={{
                                        bgcolor: "paper.main",
                                        boxShadow: "0 2px 10px rgba(0,0,0, 9%)",
                                      }}
                                    />
                                  )}
                                  {/* <IconButton size='small' sx={{position: 'absolute', right: '5%', top: '4%', bgcolor: '#fff'}}><FavoriteBorder fontSize='small'/></IconButton> */}
                                </Box>
                                <Stack textAlign={"center"}>
                                  <Typography
                                    variant="h5"
                                    sx={{
                                      textTransform: "capitalize",
                                      fontWeight: 700,
                                      mb: 0.5,
                                      // fontSize: "1.1rem",
                                    }}
                                  >
                                    {el?.title}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 300, mb: 2 }}
                                    color={"GrayText"}
                                    noWrap
                                  >
                                    {el?.description}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    sx={{ fontWeight: 300, mb: 2 }}
                                    noWrap
                                  >
                                    {el?.price.toFixed(2)}$
                                  </Typography>
                                  <Box sx={{ marginTop: "1rem" }}>
                                    <List>
                                      {el?.items?.map((item, index) => {
                                        let type = Object.keys(item)[0];
                                        return (
                                          <ListItem key={index}>
                                            <ListItemAvatar>
                                              <Avatar
                                                src={item[type]?.image}
                                              ></Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                              primary={`${item[type]?.title} x${item?.quantity}`}
                                            ></ListItemText>
                                            {/* {item[type]?.title} - Qty:{" "}
                                        {item?.quantity} */}
                                          </ListItem>
                                        );
                                      })}
                                    </List>
                                  </Box>
                                </Stack>
                              </Box>
                            </Grid>
                          );
                        })
                      ) : (
                        <Box
                          textAlign={"center"}
                          padding={"4rem"}
                          width={"100%"}
                        >
                          <img src={MenuImage} width={"12%"} alt="no_menu" />
                          <Typography variant="h6" mt={3}>
                            No Combos
                          </Typography>
                          <Typography variant="body1" color={"GrayText"}>
                            Sorry we are yet to add our combos{" "}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item sm={3.5}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid lightgrey",
                        height: "fit-content",
                        padding: "15px",
                        position: "sticky",
                        top: 10,
                      }}
                    >
                      {comboMeal ? (
                        <MealDetailItem
                          meal={comboMeal}
                          addedToCart={setMeal}
                        />
                      ) : (
                        <Box
                          height={"30rem"}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Fastfood
                            sx={{ fontSize: "6rem", color: grey[300] }}
                          />
                          <Typography color={"GrayText"} mt={2}>
                            No Combo Meal Selected
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
              {/* <Container maxWidth="xl" sx={{ marginTop: "3rem" }}>
                <Stack
                  mb={"1.5rem"}
                  direction={{ xs: "column", md: "row" }}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h3">Reviews</Typography>
                </Stack>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      mt={1}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        display="flex"
                        textAlign="center"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          gap={3}
                        >
                          <Star color="secondary" sx={{ fontSize: "4rem" }} />
                          <Typography variant="h2" display="block">
                            8.9
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignContent="center"
                          marginLeft="1.5rem"
                        >
                          <Typography
                            variant="caption"
                            display="block"
                            sx={{
                              fontSize: "2rem",
                            }}
                          >
                            Very Good
                          </Typography>
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontSize: {
                                xs: "1.5rem",
                              },
                              fontStyle: "italic",
                            }}
                          >
                            350 Reviews
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={2}>
                    {[1, 2, 3, 4].map((d) => (
                      <Grid item xs={12} display="flex" justifyContent="center">
                        <Box
                          display="flex"
                          sx={{
                            border: "1px solid grey",
                            height: "200px",
                            width: "60%",
                          }}
                        >
                          <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='center'
                          justifyContent='center'
                          gap={3}
                          sx={{
                            flex: '.2'
                          }}>
                            <Avatar></Avatar>
                            <Typography>
                              Lukas
                            </Typography>
                          </Box>
                          <Box
                            height>

                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Container> */}
            </Box>
          )}
        </Box>
      )}

      {/* Loading Dishes */}
      <Dialog open={open} maxWidth="sm">
        <DialogContent sx={{ padding: "3rem 1rem", textAlign: "center" }}>
          <img src={TRAY} width={"15%"} alt="loading" />
          {/* <LinearProgress color='primary' variant='indeterminate' /> */}
          <Typography mt={0}>Fetching meals. Please wait</Typography>
        </DialogContent>
      </Dialog>

      <Dialog open={openRatingModal} sx={{ paddingBottom: "0 !important" }}>
        <DialogTitle>Rate Us!</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Rating
            name="hoverRating"
            value={ratingValue}
            precision={0.5}
            getLabelText={getLabelText}
            onChange={handleRatingValueChange}
            onChangeActive={(e, newHover)=>setHoverRating(newHover)}
            sx={{ fontSize: "3rem", mb: 2 }}
            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          />{ratingValue !== null && (
            <Box sx={{ ml: 2 }}>{labels[hoverRating !== -1 ? hoverRating : ratingValue]}</Box>
          )}
          <Typography mt={2}>Thank you for your time !</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "orange" }}
            onClick={() => setOpenRatingModal(false)}
          >
            Cancel
          </Button>
          <LoadingButton 
            loading={submittingRating} 
            onClick={onSubmitRating}
            >Submit</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RestaurantDetail;
