import { KeyboardArrowRight, ReceiptLong } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import OrderItem from "../../components/Order/Item/OrderItem";
import { grey } from "@mui/material/colors";
import MealItem from "../../components/Meal/Item/MealItem";

const borderRadius = "15px";

const Dashboard = () => {
  return (
    <>
      <Box padding={"6rem 0"} bgcolor={grey[100]}>
        <Container maxWidth="xl">
          <Grid container spacing={5}>
            <Grid item sm={8.5}>
              <Card
                elevation={0}
                sx={{
                  boxShadow: "0 2px 10px rgba(0,0,0, 9%)",
                  borderRadius: borderRadius,
                  mb: "3rem",
                }}
              >
                <CardContent sx={{ p: "2rem" }}>
                  <Typography variant="h6" sx={{ fontWeight: 400 }}>
                    Hi, Jeremiah!
                  </Typography>
                  <Typography variant="h4" sx={{ mb: "1rem", fontWeight: 600 }}>
                    Welcome to ClickSo
                  </Typography>
                  <Typography>
                    Amet et eu tempus lacinia diam. Nibh scelerisque tempus
                    lectus ultricies gravida erat cras semper. Eget enim
                    facilisis urna vivamus velit sapien mauris. Habitasse libero
                    lectus molestie nunc vitae. Risus nunc molestie viverra in
                    ullamcorper. Ullamcorper sed leo.
                  </Typography>
                </CardContent>
              </Card>

              {/* Orders */}
              <Box>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mb={2}
                >
                  <Typography variant="h6">Recent Orders</Typography>
                  <Button
                    variant="text"
                    size="small"
                    endIcon={<KeyboardArrowRight fontSize="small" />}
                  >
                    View More
                  </Button>
                </Stack>
                <Grid container spacing={2}>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
                    return (
                      <Grid item sm={3}>
                        <OrderItem />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>

              {/* Meals */}
              <Box mt={4}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mb={2}
                >
                  <Typography variant="h6">Recent Meals</Typography>
                  <Button
                    variant="text"
                    size="small"
                    endIcon={<KeyboardArrowRight fontSize="small" />}
                  >
                    View More
                  </Button>
                </Stack>
                <Grid container spacing={2}>
                  {[1, 2, 3, 4, 5, 6].map((item, index) => {
                    return (
                      <Grid item sm={4}>
                        <MealItem />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>

            <Grid item sm={3.5}>
              <Box
                sx={{
                  bgcolor: "#fff",
                  textAlign: "center",
                  position: "sticky",
                  top: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30rem",
                  border: "1px solid lightgrey",
                  padding: "1.5rem",
                  borderRadius: "10px",
                }}
              >
                <ReceiptLong
                  sx={{ fontSize: "7rem", color: grey[300], mb: 2 }}
                />
                <Typography>No Activity Selected</Typography>
                <Typography color={"GrayText"} variant="body2">
                  Select an order/meal to preview here
                </Typography>
              </Box>
              {/* <OrderDetails /> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
