import { combineReducers } from "redux";

// Auth Reducer
export const drawerReducer = (cart = { open: false, items: [] }, action) => {
  switch (action.type) {
    case "DRAW_OPEN":
      return { ...cart, open: true };
    case "DRAW_CLOSE":
      return { ...cart, open: false };
    case "ADDTOCART":
      console.log(action.payload)
      return { ...cart, items: [...cart.items, action.payload] };
    case "REMOVEFROMCART":
      const filtered = cart?.items.toSpliced(cart?.items?.findIndex((el) => el?._id === action.payload), 1);
      return { ...cart, items: filtered };

    case "CLEAR_CART":
      return { open: false, items: [] };
    default:
      return cart;
  }
};

export const loaderReducer = (loader = false, action) => {
  switch (action.type) {
    case "LOADING":
      return true;
    case "EXIT_LOADING":
      return false;
    default:
      return loader;
  }
};

export const userReducer = (user = null, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      return {...action.payload};
    case "LOGIN_USER":
      return action.payload;
    case "LOGOUT_USER":
      return (user = null);
    case "NO_USER":
      return user;
    default:
      return user;
  }
};

export const favoriteReducer = (favorites = null, action) => {
  switch (action.type) {
    case 'UPDATE FAVORITES':
      return action.payload;
    default:
      return favorites;
  }
};


export const modalReducer = (modal = null, action) => {
  switch (action.type) {
    case "SUCCESS":
      return { message: action.payload, status: "success" };
    case "ERROR":
      return { message: action.payload, status: "error" };
    case "RESET_MODAL":
      return null;
    default:
      return modal;
  }
};

export const restaurantReducer = (
  restoState = {
    restaurants: [],
    notifications: [],
    dishes: [],
    orders: [],
    favorites: [],
    favoritesAdded: 0,
    orderToBePlaced: {},
    orderSet: false,
  },
  action
) => {
  switch (action.type) {
    case "ALL_RESTO":
      return { ...restoState, restaurants: action.payload };
    case "ALL_NOTIFICATIONS":
      return { ...restoState, notifications: action.payload };
    case "ALL_ORDERS":
      return { ...restoState, orders: action.payload };
    case "ALL_DISHES":
      return { ...restoState, dishes: action.payload };
    case "ALL_FAVORITES":
      return { ...restoState, favorites: action.payload };
    case "STORE ORDER TO BE PLACED":
      return { ...restoState, orderToBePlaced: action.payload, orderSet: true };
    case "CLEAR ORDER THAT WAS PLACED":
      return { ...restoState, orderToBePlaced: {}, orderSet: false };
    case "ADD TO FAVORITE":
      return { ...restoState, favoritesAdded: restoState.favoritesAdded + 1 };
    case "RESET_RESTO":
      return restoState;
    default:
      return restoState;
  }
};

export default combineReducers({
  cart: drawerReducer,
  currentUser: userReducer,
  loader: loaderReducer,
  modal: modalReducer,
  resto: restaurantReducer,
  favorite: favoriteReducer
});
