import React, { useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { authRequest } from "../../../services/services";
import { connect, useDispatch, useSelector } from "react-redux";

const PrivateRoute = (props) => {
  const { authRequest } = props;
  const currentUser = useSelector((state) => state.currentUser);
  const path = useLocation().pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    authRequest();
  }, [authRequest, dispatch]);

  if (!currentUser) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <Navbar />
      <Outlet />

      {path === "/login" || path === "/register" ? null : <Footer />}
    </>
  );
};

const mapStateToprops = (state) => state;

export default connect(mapStateToprops, { authRequest })(PrivateRoute);
