import { Adb, Shop } from "@mui/icons-material";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Phone from "../../assets/phone.png";
import Pizza from "../../assets/h7_burger.png";
import React from "react";
import { wrap } from "lodash";

const Footer = () => {
  return (
    <>
      {/* Download APPS */}
      <Box bgcolor={"#f7f2e2"} padding={"3rem 0"}>
        <Container maxWidth="lg">
          <Grid container spacing={4} sx={{
            margin: '0',
            width: '100%'
          }}>
            <Grid item sm={4} sx={{
                padding: '1rem !important'
            }}>
              <Box sx={{ position: "relative" }}>
                <img src={Phone} width={"80%"} alt="clickso_phone" />
                <img
                  src={Pizza}
                  style={{
                    width: "80%",
                    position: "absolute",
                    bottom: "-5%",
                    left: "-30%",
                  }}
                  alt="clickso_burger"
                />
              </Box>
            </Grid>
            <Grid
              item
              sm={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: '1rem !important'
              }}
            >
              <Typography variant="h4" mb={3} sx={{ fontWeight: 600 }}>
                {/* Download the ClickSo Apps today! */}
                Téléchargez ClickSo dès aujourd'hui!              </Typography>
              <Typography color={"GrayText"} mb={4}>
                {" "}
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Nesciunt dolore laudantium molestiae repellat repudiandae,
                voluptates doloremque aut consequatur alias autem exercitationem
                ratione soluta quam sapiente quia, quasi esse qui ab.
              </Typography>
              <Stack display={"none"} direction={"row"} gap={"2rem"} sx={{
                width: 'fit-content'
              }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "1rem 1.5rem",
                  }}
                >
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <Shop fontSize="large" />
                    <span>
                      <Typography variant="body2" textAlign={"left"}>
                        For Users
                      </Typography>
                      <Typography variant="h5" mt={-1}>
                        Google Play
                      </Typography>
                    </span>
                  </Stack>
                </Button>
                <Button
                  variant="contained"
                  color="tertiary"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "1rem 1.5rem",
                  }}
                >
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <Adb fontSize="large" />
                    <span>
                      <Typography variant="body2" textAlign={"left"}>
                        For Restaurants
                      </Typography>
                      <Typography variant="h5" mt={-1}>
                        Google Play
                      </Typography>
                    </span>
                  </Stack>
                </Button>
              </Stack>
              <Box sx={{
                display: 'flex',
                width: '100%',
                gap: '2rem',
                flexWrap: 'wrap'
              }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "1rem 1.5rem",
                  }}
                >
                  <Stack direction={"row"} gap={2} alignItems={"center"} sx={{
                  }}>
                    <Shop fontSize="large" />
                    <span>
                      <Typography variant="body2" textAlign={"left"} mb={1}>
                        Clients
                      </Typography>
                      <Typography variant="h5" mt={-1} fontSize={'1.6rem'}>
                        Google Play
                      </Typography>
                    </span>
                  </Stack>
                </Button>
                <Button
                  variant="contained"
                  color="tertiary"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "1rem 1.5rem",
                  }} 
                >
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <Adb fontSize="large" />
                    <span>
                      <Typography variant="body2" textAlign={"left"} mb={1}>
                        Restaurants
                      </Typography>
                      <Typography variant="h5" mt={-1} fontSize={'1.6rem'}>
                        Google Play
                      </Typography>
                    </span>
                  </Stack>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor={grey[900]} height={"20rem"}></Box>
      <Box bgcolor={"tertiary.main"} padding={"1rem"} />
    </>
  );
};

export default Footer;
