import { Restaurant } from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import MealBg from "../../../assets/greyclick.jpg";
import { grey } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MealItem = ({
  onClick,
  cart,
  name,
  price,
  image,
  description,
  discount,
  restaurant,
  dataX
}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentUser = useSelector(state => state.currentUser)
  const cartX = useSelector((state) => state.cart);

  const addToCart = () => {

    if (!currentUser) {
      // const callBackUrl = encodeURIComponent(path);
      dispatch({type: 'ERROR', payload: 'Login to add to cart'})
      navigate(`/login`);
      return;
    }

    setTimeout(() => {
      if (
        cartX.items.length > 0 &&
        cartX?.items[0]?.restaurant !== restaurant
      ) {
        dispatch({
          type: "ERROR",
          payload: "Cannot add meal from another restaurant",
        });
      } else {
        dispatch({
          type: "ADDTOCART",
          payload: {
            ...dataX,
            restaurant,
            quantity: 1,
            total: dataX?.price * 1,
          },
        });
        dispatch({ type: "SUCCESS", payload: "Meal added to cart" });
        
      }
    }, 500);
  };

  return (
    <Box
      padding={"1rem"}
      onClick={onClick}
      bgcolor={"#fff"}
      borderRadius={"20px"}
      border={`1px solid ${grey[300]}`}
      sx={{
        transition: "all .3s ease-in",
        ":hover": {
          border: (theme) => `1px solid ${theme.palette.secondary.main}`,
        },
      }}
    >
      <Box
        height={"12rem"}
        bgcolor={"#eee"}
        borderRadius={"15px"}
        mb={3}
        padding={"1rem"}
        sx={{
          backgroundImage: `url(${image || MealBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        {discount?.is_discount && (
          <Chip
            label={
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {discount?.discount_percentage}%
              </Typography>
            }
            size="small"
            sx={{
              bgcolor: "paper.main",
              boxShadow: "0 2px 10px rgba(0,0,0, 9%)",
            }}
          />
        )}
      </Box>
      <Box>
        {/* <Rating value={4.0} color="secondary" size="small" /> */}
        <Typography 
        variant='h4'
        component='div'
        gutterBottom
        sx={{ 
          // fontWeight: 500,
          textTransform: "capitalize",
          mb: 1,
          ":hover": {
            cursor: "pointer",
          },
        }}>{name}</Typography>
        <Typography
          variant="body2"
          color={"text.secondary"}
          noWrap
        >
          {description}
        </Typography>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <span style={{ display: "flex", gap: "1rem" }}>
            {price && <Typography variant='h5' color='#D0202A' >{price || 0}€</Typography>}
          </span>
          {cart ? (
            <IconButton
              sx={{
                bgcolor: "secondary.main",
                color: "#fff",
                borderRadius: "13px",
              }}
            >
              <Restaurant fontSize="small" />
            </IconButton>
          ) : null}
        </Stack>
      </Box>
    </Box>
  );
};

export default MealItem;
