import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import Counter from "../../Counter/Counter";
import { useDispatch } from "react-redux";
import CartExtraItem from "../Extra Item/CartExtraItem";

const CartItem = ({ id, name, image, price, quantity, extras, total }) => {
  const dispatch = useDispatch();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: ".7rem",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          my: "1rem",
        }}
      >
        <Box
          width={"5.5rem"}
          height={"5.5rem"}
          sx={{
            bgcolor: grey[300],
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
          }}
          borderRadius={"10px"}
        />
        <Box>
          <Typography sx={{ fontWeight: 500, fontSize: ".9rem" }} noWrap>
            {name}
          </Typography>
          <Typography
            color={"primary.main"}
            sx={{ fontSize: "1rem", fontWeight: 500, mb: 1 }}
          >
            {total.toFixed(2)} €
          </Typography>
          <Counter count={quantity} size={"small"} />
        </Box>
        <IconButton
          onClick={() => dispatch({ type: "REMOVEFROMCART", payload: id })}
          size="small"
          sx={{
            ml: "auto",
            border: "1px solid lightgrey",
            ":hover": { bgcolor: "primary.main", color: "#fff" },
          }}
        >
          <Close sx={{ fontSize: ".8rem" }} />
        </IconButton>
      </Box>
      {extras?.length > 0 &&
        extras.map((extra, index) => (
          <div key={index}>
            <CartExtraItem
              data={extra}
            ></CartExtraItem>
          </div>
        ))}
    </>
  );
};

export default CartItem;
