import { io } from "socket.io-client";

const URL = "http://localhost:4000/restaurant";
const socket = io(URL, { autoConnect: false });

export const initiateSocketConnectionAndListen = (userInfo, cb) => {
  socket.auth = { userId: userInfo?._id, username: userInfo?.username};

  socket.connect();

  socket.on("order:ack", (data) => {
    console.log(`Order from ${data.backTo} processing by ${data.processingBy}`);
  })

  socket.on("order/status:update", (data)=>{
    let type = 'SUCCESS'
    switch (data.status.toLowerCase()) {
      case "cancelled":
        type = "ERROR"
        break;
      default:
        type = "SUCCESS"
    }

    if(data) {
      
      cb({type, payload: `
      Votre commande N. ${data?.orderNumber} avec ${data?.restaurant_name} est ${data?.status}
      `})
    }
  })

};

export const notifyRestaurantForOrderPlaced = (order) => {
  const restaurantId = order?.restaurant;
  // socket.emit("order:place", {
  //   sentBy: "Amenda",
  //   orderDetails: "details of orders",
  //   to: order?.dishes[0]?.restaurantID
  // })
  socket.emit("order:place", {restaurantId, orderDetails: order})
}

export const listenToRestaurantOpeningStatusUpdate = (cb) => {
  console.log('sdgbhj')
  socket.on("restaurant/openOrClose", ()=>{
    console.log('func called')
    cb()
  })
}



export default socket;
