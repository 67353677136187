import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
// import Counter from './Counter'
// import { grey } from '@mui/material/colors'
import MealBg from "../../../assets/greyclick.jpg";
import _ from "lodash";
import Counter from "../../Counter/Counter";

const CartExtraItem = (
  // { id, text, price}
  { data }
) => {
  const [val, setVal] = useState(false);
  const [number, setNumber] = useState(0);
  const [qty, setQty] = useState(0);
  const [extraInfo, setExtraInfo] = useState(JSON.parse(data[0]));

  // const addQty = () => {
  //   setQty((prev) => {
  //     add({ id, text: text?.trim(), price });
  //     return prev + 1;
  //   });
  // };
  // const removeQty = () => {
  //   if (qty === 0) {
  //     return;
  //   } else {
  //     setQty((prev) => {
  //       remove({ id, text: text?.trim(), price });
  //       return prev - 1;
  //     });
  //   }
  // };

  return (
    <Stack direction={"row"} gap={"1rem"} alignItems={"center"} my={1}>
      <Box
        sx={{
          height: "3rem",
          width: "3rem",
          backgroundImage: `url(${MealBg})`,
          backgroundSize: "cover",
          borderRadius: "10px",
        }}
      />
      <Box>
        <Typography sx={{ fontWeight: 500, fontSize: "1rem" }} noWrap>
          {_.startCase(extraInfo?.text)}
        </Typography>
        <Typography
          color={"primary.main"}
          sx={{ fontSize: "1rem", fontWeight: 500, mb: 1 }}
        >
          {extraInfo?.price?.toFixed(1)} €
        </Typography>
      </Box>
      <Box
        ml={"auto"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // width: "20%",
          gap: "0.5rem",
          padding: "0.5rem",
        }}
      >
        <Counter count={data[1]} />
      </Box>
    </Stack>
  );
};

export default CartExtraItem;
