// CobmoContext.js
import React, { createContext, useState } from 'react';

const ComboContext = createContext();

const ComboProvider = ({ children }) => {
  const [combo, setCombos] = useState({});

  return (
    <ComboContext.Provider>
      {children}
    </ComboContext.Provider>
  );
};

export { ComboContext, ComboProvider };