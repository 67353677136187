import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState, useReducer } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userAccountAPI, verifyAccount } from "../../services/services";
import { decryptData } from "../../services/services";
import { userReducer } from "../../reducers";

const userState = {
  email: "",
  phone: "",
  username: "",
  password: "",
};

const VerifyAccount = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [resendLoad, setResendLoad] = useState(false);
  const [decryptedDataX, setDecryptedDataX] = useState({});
  const [userInput, userDispatch] = useReducer(userReducer, userState);

  useEffect(() => {
    const checkDataAndDecrypt = () => {
      try {
        const data = localStorage.getItem('xyz')
        const x = decryptData(data)
        const decryptedData = JSON.parse(x)
        setDecryptedDataX(decryptedData)
      } catch (error) {
        console.log(error)
        // navigate('/')
      }
    }
    checkDataAndDecrypt()
  }, [navigate]);

  const [otp, setOtp] = useState("");

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);

    try {
      // const status = await verifyAccount(decryptedDataX, otp, dispatch)
      const response = await userAccountAPI.validateOtp({email: decryptedDataX?.email, otp}).catch(e=>e);
  
      if (response.success) {
        dispatch({ type: "SUCCESS", payload: "Login successful" });

        
        dispatch({ type: "LOGIN_USER", payload: response.data})
        userDispatch({ type: "RESET" });
        setLoad(false);
        localStorage.clear("xyz");
        localStorage.setItem("token", response.data.token)
        return navigate("/");
      } else {
        dispatch({type: "ERROR", payload: response || 'An Error has occurred'})
        setLoad(false);
      }
    } catch (err) {
      dispatch({type: "ERROR", payload: err || 'An Error has occurred'})
      setLoad(false);
    }
  };

  const resendOtp = async (event) => {

    setResendLoad(true);

    try {
      // const status = await verifyAccount(decryptedDataX, otp, dispatch)
      const response = await userAccountAPI.sendOtp({email: decryptedDataX?.email}).catch(e=>e);
  
      if (response.success) {
        dispatch({ type: "SUCCESS", payload: "OTP sent successfully" });
        setResendLoad(false);
      } else {
        dispatch({type: "ERROR", payload: response || 'An Error has occurred'})
        setResendLoad(false);
      }
    } catch (err) {
      dispatch({type: "ERROR", payload: err || 'An Error has occurred'})
      setResendLoad(false);
    }
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "50%",
          bgcolor: "#fff",
          borderRadius: "8px",
          padding: {
            xs: "1rem",
            md: "4rem",
          },
          textAlign: "center",
          boxShadow: "0 10px 30px rgba(0,0,0, 12%)",
          "& > span": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "1rem",
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            mt: "0rem",
            mb: ".4rem",
            fontSize: {
              xs: "1.4rem",
              sm: "1.8rem",
            },
          }}
        >
          Verify your account !
        </Typography>
        <Typography
          variant="body1"
          mb="1rem"
          color={"GrayText"}
          sx={{
            paddingBottom: "1rem",
            fontSize: {
              xs: "0.9rem",
              sm: "1.rem",
            },
          }}
        >
          Please enter the OTP sent to your email or phone number
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Enter OTP"
            // type='number'
            variant="outlined"
            value={otp}
             onChange={handleOtpChange}
            sx={{
              width: "50%",
              paddingBottom: "1rem",
            }}
          />
          <br />
          <Button
            type="submit"
            disabled={resendLoad}
            variant="contained"
            color="primary"
            sx={{
              width: "50%",
              marginTop: "1rem",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </form>
        <Button
          type="button"
          onClick={resendOtp} 
          sx={{ 
            textDecoration: "none", 
            fontWeight: 500, 
            color: "#D0202A", 
            marginTop: "1rem" 
            }}>
            Resend OTP
        </Button>
        {/* <Typography variant='body2' color={'GrayText'} textAlign='center'>By continuing, you  agree to our Terms of Use and Privacy Policy</Typography> */}
      </Box>
    </Box>
  );
};

const mapStateToprops = (state) => state;

export default connect(mapStateToprops, { verifyAccount })(VerifyAccount);
