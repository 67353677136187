import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, TextField, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { userAccountAPI } from '../../services/services'
import { useDispatch } from 'react-redux'

const ForgotPassword = () => {

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()



  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const response = await userAccountAPI.forgotPassword({email})

      if(response.success) {
        dispatch({type: 'SUCCESS', payload: response?.message})
      } else {
        dispatch({type: 'ERROR', payload: response?.message})
      }
    } catch (e) {
      dispatch({type: 'ERROR', payload: 'Internal Server Error'})
    } finally {
      setLoading(false)
    }
  } 

  return (
    <Box sx={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Box sx={{
            width: '500px', bgcolor: '#fff', 
            borderRadius: '8px', padding: '3rem', 
            textAlign: 'center', boxShadow: '0 10px 30px rgba(0,0,0, 12%)',
            '& > span': { 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', mb: '1rem'
                }
            }}>
                <Typography variant='h5' sx={{fontWeight: 600, mt: '3rem', mb: '.7rem'}}>Forgot Password?</Typography>
                <Typography variant='body2' mb='2rem' color={'GrayText'}>Enter the email address associated with your account.<br />You will receive a verification code.</Typography>

                <TextField 
                  sx={{mb: '1rem'}} 
                  onChange={(e)=>setEmail(e.target.value)}
                  value={email}
                  fullWidth 
                  variant='outlined' 
                  label='Email' 
                  type={'email'} />

                <LoadingButton 
                  disableElevation
                  loading={loading}
                  type='button'
                  onClick={onSubmit}
                  size='large' 
                  fullWidth 
                  variant='contained' 
                  color='primary' 
                  sx={{textTransform: 'none', color: '#fff', height: '3.5rem', mb: '2rem'}}>Send Email</LoadingButton>
                <Typography textAlign={'center'} mb='2rem'>Return to <Link to={'/login'} style={{textDecoration: 'none', fontWeight: 500, color: '#FF8C00'}}>Login</Link></Typography>
        </Box>
    </Box>
  )
}

export default ForgotPassword