import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
// import "./index.stripe.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(
  "pk_test_51MEvq4C582oYJ4LSsyY6FUi5JxtMdRXQHi64oTBsCk1fARYeobD2Nqg6et0g8AyUYIib40eguK3lFDubKpBjkf1000fr18kg41"
);

export default function StripeApp({ data }) {
  // const [clientSecret, setClientSecret] = useState("");

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: data,
    appearance,
  };


  return (
    <>
      <div className="rootX">
        <div className="bodyX">
          <div className="App">
            {data && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
