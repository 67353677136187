import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
// import Banner from '../components/Banner'
import Pizza from "../../../assets/h7_burger.png";
import FriedChicken from "../../../assets/rev_home.png";
import Tomato from "../../../assets/h1_tomato.png";
import Burger from "../../../assets/h2_burger.png";
import BhShape from "../../../assets/h1_shape-1.png";
import CircleShape from "../../../assets/h1_circle-1.png";
import CircleGrey from "../../../assets/h1_circle-2.png";
import CircleBg from "../../../assets/h1_banner2.jpg";
import CircleLoc from "../../../assets/h1_location.png";
import Crispy from "../../../assets/h1_chicken.png";
import Salad from "../../../assets/h1_salad.png";
import BgBurger from "../../../assets/h1_burger-1.png";
import { grey } from "@mui/material/colors";
import { ShoppingBagOutlined } from "@mui/icons-material";
import OwlCarousel from "react-owl-carousel";
import MealItem from "../../../components/Meal/Item/MealItem";
import RestaurantItem from "../../../components/Restaurant/Item/RestaurantItem";
import { useNavigate } from "react-router-dom";
import {
  homePageAPI,
  restaurantAPI,
  userAccountAPI,
} from "../../../services/services";
import { useDispatch, useSelector } from "react-redux";
import { categories } from "../../../constant";
import MenuImage from "../../../assets/menus.png";
import "./Homepage.css";

import "../../../css/animations.css";
import { listenToRestaurantOpeningStatusUpdate } from "../../../services/socket";
import DishCard from "../../../components/DishCard/dishCard.main";
import DishGrid from "../../../components/DishCard/dishCard.main";

const boxColors = [
  "filter: invert(18%) sepia(36%) saturate(7293%) hue-rotate(344deg) brightness(99%) contrast(105%)",
  "filter: invert(53%) sepia(54%) saturate(3315%) hue-rotate(154deg) brightness(98%) contrast(100%)",
  "filter: invert(74%) sepia(10%) saturate(2036%) hue-rotate(37deg) brightness(101%) contrast(87%)",
  "filter: invert(90%) sepia(39%) saturate(2604%) hue-rotate(318deg) brightness(98%) contrast(102%)",
];
const PropertySlider = styled(OwlCarousel)(({ theme }) => ({
  position: "relative",
  "& .owl-carousel": {
    position: "relative",

    "&:hover .owl-nav": {
      opacity: 1,
    },
    "& .owl-item img": {
      width: "20%",
    },
  },
  "& .owl-nav": {
    // opacity: 0,
    width: "100%",
    position: "absolute",
    display: "flex",
    top: "40%",
    justifyContent: "space-between",
    transform: "TranslateY(50%)",
    transition: "all .2s ease-in",
  },
  "& .owl-prev": {
    padding: "1rem",
    background: alpha("#000", 0.5),
    borderRadius: "15px",
    color: "#fff",
  },
  "& .owl-next": {
    padding: "1rem",
    background: alpha("#000", 0.5),
    borderRadius: "15px",
    color: "#fff",
  },
}));

const FlexBox = styled(Box)({
  padding: "3rem",
  position: "relative",
  overflow: "hidden",
  color: "#fff",
  height: "23rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
});

const Homepage = () => {
  // REDUX STORE
  const currentUser = useSelector((state) => state?.currentUser);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [dishes, setDishes] = useState(false);
  const [constDishes, setConstDishes] = useState(false);
  const [or, setOpenRestaurants] = useState([]);
  const [withDiscount, setWithDiscount] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [cc, setCC] = useState("Pizza");

  const dispatch = useDispatch();

  const CategoriesMapped = useMemo(
    () =>
      categories.map((category) => {
        return category.title === cc
          ? {
              ...category,
              sx: {
                color: "secondary.main",
                // cursor: "pointer",
                // backgroundImage: `url(${CircleShape})`,
                // backgroundPosition: "center",
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "130px",
                transition: "all .5s ease-in-out !important",
              },
            }
          : {
              ...category,
              sx: {
                height: 400,
                marginInline: 2,
              },
            };
      }),
    [cc]
  );

  useEffect(() => {
    Promise.all([
      // getAllRestaurantsX(),
      // getAllPopularRestaurants(),
      // getAllRestaurantsX(),
      homePageAPI.getRestaurantsWithFilters({ isOpen: "true" }),
      homePageAPI.getRestaurantsWithFilters({ discount: "true" }),
      homePageAPI.getNewArrivals(),
      homePageAPI.getDishesByCategory(cc),
    ])
      .then((values) => {
        // dispatch({ type: "ALL_RESTO", payload: values[0]?.data?.results });
        // setData(values[1]?.data);
        setOpenRestaurants(values[0]?.data);
        setWithDiscount(values[1]?.data);
        setNewArrivals(values[2]?.data);
        setDishes(values[3]?.data);
        // dispatch({ type: "ALL_DISHES", payload: values[2]?.data?.results });

        setIsLoading(false);
      })
      .catch((e) => {
        dispatch({ type: "ERROR", payload: "An Error occurred" });
        setIsLoading(false);
      });
  }, [dispatch]);

  // useEffect(() => {
  //   listenToRestaurantOpeningStatusUpdate(async()=>{
  //     const response = await restaurantAPI.getRestaurantsWithFilters({isOpen: 'true'})
  //     setOpenRestaurants(response?.data)
  //   })
  // }, [])

  // ENDPOINTS TO BE CALLED ONCE AUTHENTICATED (AFTER LOGIN)
  useEffect(() => {}, []);

  return (
    <Box paddingTop={4}>
      {/* Banner */}
      <Grid container spacing={2} padding={4}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "80vh",
              // width: "80vw",
              borderRadius: "50px",
              marginInline: "auto",
              backgroundImage: `url(${BhShape})`,
              backgroundSize: "contain",
              backgroundPosition: "center right",
              bgcolor: grey[900],
            }}
          >
            <Container maxWidth="xl">
              <Grid container columnSpacing={5}>
                <Grid
                  item
                  sm={5}
                  sx={{
                    height: "80vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  className="slide-in-left"
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#fff",
                      fontWeight: 600,
                      mb: 2,
                      // fontSize: "3.5em",
                      fontSize: "4vw",
                    }}
                  >
                    {/* {"UNLIMITED MEDIUM PIZZAS"} */}
                    Un nombre illimité de Menus
                  </Typography>
                  <Typography sx={{ mb: "2rem", color: "#fff" }}>
                    {" "}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptas cum provident alias corporis delectus possimus in
                    dolores impedit
                  </Typography>
                  <span
                    className="wobble-hor-bottom"
                    style={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      disableElevation
                      color="secondary"
                      startIcon={<ShoppingBagOutlined />}
                      href="#restaurants"
                      sx={{
                        color: "#fff",
                        fontSize: "1.2rem",
                      }}
                    >
                      {/* ORDER NOW */}
                      Passez une commande
                    </Button>
                  </span>
                </Grid>
                <Grid
                  item
                  sm={7}
                  sx={{
                    // display: {
                    //   xs: "none",
                    //   sm: "flex",
                    // },
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "relative",
                    // overflow: "hidden",
                  }}
                >
                  <img
                    src={Pizza}
                    alt="food"
                    width={"100%"}
                    style={{ zIndex: 99 }}
                    className="bounce-in-top"
                  />
                  <img
                    src={Tomato}
                    alt="food"
                    width={"50%"}
                    style={{ position: "absolute", top: "-15%", left: 0 }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {Array(4)
              .fill(0)
              .map((n, index) => (
                <Grid justifyContent="center" alignItems="center" item xs={6}>
                  <Box
                    sx={{
                      height: "35vh",
                      // width: "80vw",
                      position: "relative",
                      borderRadius: "50px",
                      marginInline: "auto",
                      backgroundImage: `url(${BhShape})`,
                      filter: boxColors[index].split(":")[1],
                      backgroundSize: "contain",
                      backgroundPosition: "center right",
                      bgcolor: grey[900],
                    }}
                  >
                    <img
                      src={Pizza}
                      alt="food"
                      width={"100%"}
                      style={{ zIndex: 99 }}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>

      <DishGrid></DishGrid>

      {/* Categories */}
      <Box sx={{ padding: "5rem 0", position: "relative" }}>
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            className="oswald-categoryCard"
            mb={"2rem"}
            textalign={"center"}
          >
            {/* With Discount */}
            Popular Categories
          </Typography>
          <PropertySlider
            key={cc}
            margin={20}
            loop={true}
            autoplay={true}
            autoplaySpeed={500}
            smartSpeed={450}
            nav={true}
            navElement="div"
            navText={[
              `<i class='fas fa-arrow-left'></i>`,
              `<i class='fas fa-arrow-right'></i>`,
            ]}
            responsive={{
              1400: { items: "4" },
              1024: { items: "4" },
              550: { items: "2" },
              320: { items: "1" },
            }}
          >
            {CategoriesMapped?.map((el, index) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={el.title.toLowerCase() === "fries" ? 0 : 4}
                  className={"category-card-bg"}
                  key={index}
                  textalign={"center"}
                  onClick={(e) => {
                    setCC(el.title);
                    e.preventDefault();
                    if (el.title.toLocaleLowerCase() === "all")
                      return setDishes(constDishes);
                    setDishes(false);
                    homePageAPI
                      .getDishesByCategory(el.title.toLowerCase())
                      .then((response) => {
                        setDishes(response?.data);
                      })
                      .catch((e) =>
                        dispatch({
                          type: "ERROR",
                          payload: "An Error has occurred",
                        })
                      );
                  }}
                  padding={2}
                  borderRadius={"20px"}
                  sx={el.sx}
                >
                  <img
                    src={el.image}
                    className={el.title === "Pizza" ? "rotate-infinitely" : ""}
                    style={{
                      width: "200px",
                      height: "auto",
                      display: "block",
                      margin: "0 auto",
                    }}
                    alt={`category_${el.id}`}
                  />
                  <Typography
                    variant="h5"
                    className="category-title oswald-categoryCard"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {el.title}
                  </Typography>
                  <div
                    className="underline"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "black",
                      height: "2px",
                      width: "20%",
                      marginInline: "center",
                    }}
                  ></div>
                </Box>
              );
            })}
          </PropertySlider>
          <Divider sx={{ marginBlock: 5 }} />
          {!dishes && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          {dishes && dishes?.length > 0 && (
            <PropertySlider
              items={dishes?.length}
              margin={20}
              loop={true}
              autoPlay={true}
              autoplaySpeed={500}
              smartSpeed={450}
              nav={true}
              navElement="div"
              navText={[
                `<i class='fas fa-arrow-left'></i>`,
                `<i class='fas fa-arrow-right'></i>`,
              ]}
              responsive={{
                1400: { items: "5" },
                1200: { items: "4" },
                760: { items: "3" },
                500: { items: "2" },
                320: { items: "1" },
              }}
            >
              {dishes?.map((el, index) => {
                return (
                  <MealItem
                    key={index}
                    name={el?.title}
                    image={el?.image}
                    price={el?.price}
                    description={el?.description}
                    onClick={() =>
                      navigate(`/restaurants/${el?.restaurant?._id}`)
                    }
                    discount={data?.discount}
                  />
                );
              })}
            </PropertySlider>
          )}
          {dishes?.length <= 0 && (
            <Box
              textalign={"center"}
              padding={"4rem"}
              width={"100%"}
              sx={{
                padding: {
                  xs: 0,
                  sm: "4rem",
                },
              }}
            >
              <img src={MenuImage} width={"12%"} alt="no_menu" />
              <Typography variant="h6" mt={3}>
                No Meal for this category
              </Typography>
              <Typography variant="body1" color={"GrayText"}>
                Sorry there are no meal for this category{" "}
              </Typography>
            </Box>
          )}
        </Container>
      </Box>

      <Box bgcolor={"#f7f2e2"} padding={"5rem 0"}>
        <DishGrid></DishGrid>
        <br></br>
        <DishGrid></DishGrid>
      </Box>

      {/* Open restaurant */}
      <Box padding={"2rem 0"} id="restaurantsOpen">
        <Container maxWidth="xl" textalign={"center"}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "600" }}
            mb={"1rem"}
            textalign={"center"}
          >
            {/* Open Restaurants */}
            Restaurants Ouverts
          </Typography>

          <Box
            sx={{
              mb: "1rem",
              mt: "2rem",
              display: "block",
              position: "relative",
              marginInline: "auto",
            }}
          >
            <Grid container spacing={4}>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                  return (
                    <Grid
                      key={el + "fmeoirfmio"}
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      sx={{
                        padding: "1 !important",
                      }}
                    >
                      <Skeleton
                        sx={{ height: "22rem", mt: "-5.5rem" }}
                        animation="wave"
                      />
                    </Grid>
                  );
                })
              ) : or?.length > 0 ? (
                or?.map((meal) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={meal?.id + 1}>
                      <RestaurantItem
                        view={"grid"}
                        name={meal?.name}
                        id={meal?._id}
                        image={meal?.images?.profile}
                        deliveryTime={meal?.delivery?.time}
                        rating={meal?.avgRating}
                        serviceFee={meal?.service?.fee}
                        discount={meal?.discount}
                        dataX={meal}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box
                  textalign={"center"}
                  padding={"4rem"}
                  width={"100%"}
                  sx={{
                    padding: {
                      xs: 4,
                      sm: "4rem",
                    },
                  }}
                >
                  <img src={MenuImage} width={"12%"} alt="no_menu" />
                  <Typography variant="h6" mt={3}>
                    No Restaurants
                  </Typography>
                  <Typography variant="body1" color={"GrayText"}>
                    Sorry there are no open restaurants by this time{" "}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>

          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            onClick={() => navigate("/restaurants")}
            sx={{
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
              width: "10rem",
              textalign: "center",
            }}
          >
            {/* View More */}
            Voir Plus
          </Button>
        </Container>
      </Box>

      {/* with Discount */}
      <Box padding={"1rem 0"} id="restaurantsDiscount">
        <Container maxWidth="xl">
          <Typography
            variant="h4"
            sx={{ fontWeight: "600" }}
            mb={"1rem"}
            textalign={"center"}
          >
            {/* With Discount */}
            En Promo
          </Typography>

          <Box sx={{ mb: "2rem", mt: "2rem", display: "block" }}>
            <Grid container spacing={4}>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                  return (
                    <Grid key={el} item xs={12} md={3}>
                      <Skeleton
                        sx={{ height: "22rem", mt: "-5.5rem" }}
                        animation="wave"
                      />
                    </Grid>
                  );
                })
              ) : withDiscount?.length > 0 ? (
                withDiscount?.slice(0, 4)?.map((meal) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={meal?.id}>
                      <RestaurantItem
                        view={"grid"}
                        name={meal?.name}
                        id={meal?._id}
                        image={meal?.images?.profile}
                        deliveryTime={meal?.delivery?.time}
                        rating={meal?.avgRating}
                        serviceFee={meal?.service?.fee}
                        discount={meal?.discount}
                        dataX={meal}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box textalign={"center"} padding={"4rem"} width={"100%"}>
                  <img src={MenuImage} width={"12%"} alt="no_menu" />
                  <Typography variant="h6" mt={3}>
                    {/* No Restaurants */}
                    Pas de Restaurants
                  </Typography>
                  <Typography variant="body1" color={"GrayText"}>
                    {/* Sorry there are no restaurants with discount{" "} */}
                    Desole, aucun restaurant est en promo actuellement!
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>

          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            onClick={() => navigate("/restaurants")}
            sx={{
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
              width: "10rem",
              textalign: "center",
            }}
          >
            {/* View More */}
            Voir plus
          </Button>
        </Container>
      </Box>

      {/* Dicover New Places */}
      <Box padding={"1rem 0"} id="restaurantsNew">
        <Container maxWidth="xl">
          <Typography
            variant="h4"
            sx={{ fontWeight: "600" }}
            mb={"2rem"}
            textalign={"center"}
          >
            {/* Popular Restaurants */}
            Populaires
          </Typography>
          {/* <Stack
            direction={"row"}
            gap={"1rem"}
            justifyContent={"center"}
            mb={"2rem"}
          >
            <Button
              size="small"
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: "50px",
                color: "#fff",
                width: "8rem",
                height: "3rem",
              }}
              disableElevation
            >
              Pizza
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              sx={{ borderRadius: "50px", color: "#000", width: "8rem" }}
              disableElevation
            >
              Burger
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              sx={{ borderRadius: "50px", color: "#000", width: "8rem" }}
              disableElevation
            >
              Fries
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              sx={{ borderRadius: "50px", color: "#000", width: "8rem" }}
              disableElevation
            >
              Drinks
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              sx={{ borderRadius: "50px", color: "#000", width: "8rem" }}
              disableElevation
            >
              Chicken
            </Button>
          </Stack> */}

          <Box sx={{ mb: "2rem", mt: "2rem", display: "block" }}>
            <Grid container spacing={4}>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                  return (
                    <Grid key={el} item xs={12} md={3}>
                      <Skeleton
                        sx={{ height: "22rem", mt: "-5.5rem" }}
                        animation="wave"
                      />
                    </Grid>
                  );
                })
              ) : data?.length > 0 ? (
                data?.slice(0, 4)?.map((meal) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={meal?.id}>
                      <RestaurantItem
                        view={"list"}
                        name={meal?.name}
                        id={meal?._id}
                        image={meal?.images?.profile}
                        deliveryTime={meal?.delivery?.time}
                        rating={meal?.avgRating}
                        serviceFee={meal?.service?.fee}
                        discount={meal?.discount}
                        dataX={meal}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box textalign={"center"} padding={"4rem"} width={"100%"}>
                  <img src={MenuImage} width={"12%"} alt="no_menu" />
                  <Typography variant="h6" mt={3}>
                    {/* No Popular Restaurants */}
                    Auncun Restaurants Populaires
                  </Typography>
                  <Typography variant="body1" color={"GrayText"}>
                    {/* Sorry there are no popular restaurants{" "} */}
                    Desole, aucun restaurant est assez populaire
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>

          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            onClick={() => navigate("/restaurants")}
            sx={{
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
              width: "10rem",
              textalign: "center",
            }}
          >
            {/* View More */}
            Voir Plus
          </Button>
        </Container>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <FlexBox flex={1} sx={{ bgcolor: "tertiary.main" }}>
          <img
            src={Crispy}
            width={"80%"}
            style={{ position: "absolute", right: "-35%" }}
            alt="clickso_chicken"
          />
          <Typography variant="h6">Crispy Fried</Typography>
          <Typography variant="h3" sx={{ fontWeight: 700 }} color={"paper"}>
            Chicken
          </Typography>
          <Typography variant="body1" my={2}>
            NEW PHENOMENON BURGER TASTE
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600, mb: "2rem" }}>
            €8.99
          </Typography>
          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="paper"
            sx={{ borderRadius: "10px", color: "#000" }}
          >
            {/* View More */}
            Voir Plus
          </Button>
        </FlexBox>
        <FlexBox flex={1} sx={{ bgcolor: "secondary.main" }}>
          <img
            src={BgBurger}
            width={"80%"}
            style={{ position: "absolute", right: "-35%" }}
            alt="clickso_burger"
          />
          <Typography variant="h6">Juicy</Typography>
          <Typography variant="h3" sx={{ fontWeight: 700 }} color={"#ffc900"}>
            Burgers
          </Typography>
          <Typography variant="body1" my={2}>
            NEW PHENOMENON BURGER TASTE
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600, mb: "2rem" }}>
            €8.99
          </Typography>
          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="paper"
            sx={{ borderRadius: "10px", color: "#000" }}
          >
            {/* View More */}
            Voir Plus
          </Button>
        </FlexBox>
        <FlexBox flex={1} sx={{ bgcolor: "#ffc900" }}>
          <img
            src={Salad}
            width={"100%"}
            className="slide-right"
            style={{ position: "absolute", right: "-40%" }}
            alt="clickso_salad"
          />
          <Typography variant="h6">Fresh</Typography>
          <Typography variant="h3" sx={{ fontWeight: 700 }} color={"secondary"}>
            Salad
          </Typography>
          <Typography variant="body1" my={2}>
            NEW PHENOMENON BURGER TASTE
          </Typography>
          <Typography
            variant="h5"
            color={"text.main"}
            sx={{ fontWeight: 600, mb: "2rem" }}
          >
            €10.99
          </Typography>
          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            sx={{ borderRadius: "10px" }}
          >
            {/* View More */}
            Voir PLUS
          </Button>
        </FlexBox>
      </Box>

      {/* Top Restaurants */}
      <Box sx={{ padding: "5rem 0" }}>
        <Container maxWidth="xl">
          <Grid container spacing={5} justifyContent={"center"}>
            <Grid item sm={9}>
              <Typography variant="h4" mb={3} sx={{ fontWeight: 600 }}>
                New Deals
              </Typography>
              <Grid container spacing={3}>
                {newArrivals?.map((newArrival, index) => {
                  return (
                    <Grid item xs={12} md={4} key={index}>
                      <MealItem
                        name={newArrival?.title}
                        price={newArrival?.price}
                        image={newArrival?.image}
                        description={newArrival?.description}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              item
              md={3}
              sx={{
                width: "99%",
              }}
            >
              <Box
                bgcolor={"tertiary.main"}
                height={"100%"}
                width={"100%"}
                borderRadius={"15px"}
                sx={{
                  position: "relative",
                  color: "#fff",
                  textalign: "center",
                  overflow: {
                    xs: "hidden",
                  },
                }}
              >
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "block",
                    },
                  }}
                >
                  <img
                    className="register-with-us_image"
                    src={FriedChicken}
                    alt="clickso_chicken"
                  />
                </Box>
                <Box>
                  <img
                    className="register-with-us_image-2"
                    src={Pizza}
                    alt="clickso_pizza"
                  />
                </Box>
                <Box
                  sx={{
                    zIndex: 999,
                    padding: {
                      xs: "1rem",
                      md: "0",
                    },
                    position: {
                      xs: "relative",
                      md: "static",
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography textalign={"center"} pt={10}>
                    Super Delicious
                  </Typography>
                  <Typography
                    variant="h4"
                    textalign={"center"}
                    sx={{
                      fontSize: {
                        xs: "2rem",
                        xl: "3rem",
                      },
                      fontWeight: 700,
                    }}
                  >
                    CHICKEN
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 300,
                      mb: 2,
                      paddingInline: {
                        xs: 0,
                        md: 0,
                      },
                    }}
                    noWrap
                  >
                    Quisque pretium turpis non tempus cursus.
                  </Typography>
                  <Button
                    variant="contained"
                    color="paper"
                    sx={{
                      color: "#000",
                      marginBlock: {
                        xs: 1,
                        md: 0,
                      },
                    }}
                    onClick={() => navigate("/register")}
                  >
                    {/* Register With Us */}
                    créer un compte
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Download APPS */}
      {/* <Box bgcolor={'#f7f2e2'} padding={'3rem 0'}>
            <Container maxWidth='lg'>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <Box sx={{position: 'relative'}}>
                            <img src={Phone} width={'100%'} alt='clickso_phone' />
                            <img src={Pizza} style={{width: '100%', position: 'absolute', bottom: '-5%', left: '-30%'}} alt='clickso_burger' />
                        </Box>
                    </Grid>
                    <Grid item sm={8} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
                        <Typography variant='h4' mb={3} sx={{fontWeight: 600}}>Download the ClickSo Apps today!</Typography>
                        <Typography color={'GrayText'} mb={4}> Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt dolore laudantium molestiae repellat repudiandae, voluptates doloremque aut consequatur alias autem exercitationem ratione soluta quam sapiente quia, quasi esse qui ab.</Typography>
                        <Stack direction={'row'} gap={'2rem'}>
                            <Button variant='contained' color='secondary' sx={{textTransform: 'none', color: '#fff', padding: '1rem 1.5rem'}}>
                                <Stack direction={'row'} gap={2} alignItems={'center'}>
                                    <Shop fontSize='large' />
                                    <span>
                                        <Typography variant='body2' textalign={'left'}>For Users</Typography>
                                        <Typography variant='h5' mt={-1}>Google Play</Typography>
                                    </span>
                                </Stack>
                            </Button>
                            <Button variant='contained' color='tertiary' sx={{textTransform: 'none', color: '#fff', padding: '1rem 1.5rem'}}>
                                <Stack direction={'row'} gap={2} alignItems={'center'}>
                                    <Adb fontSize='large' />
                                    <span>
                                        <Typography variant='body2' textalign={'left'}>For Restaurants</Typography>
                                        <Typography variant='h5' mt={-1}>Google Play</Typography>
                                    </span>
                                </Stack>
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>

        </Box> */}
    </Box>
  );
};

export default Homepage;
