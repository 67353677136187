import React, { useMemo, useState } from "react";
import { Circle, Star } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  Box,
  Chip,
  IconButton,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import MealBg from "../../../assets/greyclick.jpg";
import { useNavigate } from "react-router-dom";
import { workingDaysSchedule } from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { userAccountAPI } from "../../../services/services";

const RestaurantItem = ({
  id,
  image,
  rating,
  deliveryTime,
  view,
  discount,
  serviceFee,
  dataX,
}) => {

  // REDUX STORE
  const currentUser = useSelector((state) => state.currentUser);
  const favourites = useSelector((state) => state?.favorite);



  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addingToFav, setAddingToFav] = useState(false);
  

  const temporaryClosed = useMemo(() => {
    return dataX?.workingDaysSchedule?.length <= 0 ? true : false;
  }, [dataX]);

  return (
    <>
      {view === "list" && (
        <Box
          padding={"1rem"}
          position={"relative"}
          borderRadius={"20px"}
          border={`1px solid ${grey[300]}`}
          display={"flex"}
          flexWrap={"wrap"}
          gap={"1rem"}
          sx={{
            transition: "all .3s ease-in",
            ":hover": {
              cursor: temporaryClosed ? "not-allowed" : "pointer",
              border: (theme) => `1px solid ${theme.palette.secondary.main}`,
            },
          }}
        >
          <IconButton
            size="medium"
            sx={{
              position: "absolute",
              right: "2%",
              top: "5%",
              bgcolor: grey[100],
              zIndex: 9999,
              cursor: addingToFav ? "wait" : "pointer",
            }}
            onClick={async () => {
              if (!currentUser) {
                return dispatch({
                  type: "ERROR",
                  payload: "Kindly Login to add to your favorites",
                });
              }
              setAddingToFav(true);
              const favFound = await favourites.find(
                (f) =>
                  f?.id?._id === id
              );
              const op = favFound ? "remove" : "add";
              // userAccountAPI
              //   .favoriteOp({
              //     id,
              //     itemModel: "Restaurant",
              //     op,
              //   })
              //   .then((response) => {
              //     if (response.success) {
              //       dispatch({ type: "ADD TO FAVORITE" });
              //       dispatch({
              //         type: "UPDATE_USER",
              //         payload: { ...currentUser, favourites: response?.data },
              //       });
              //       dispatch({ type: "SUCCESS", payload: response?.message });
              //     } else {
              //       dispatch({
              //         type: "ERROR",
              //         payload: "Error while adding to favorite",
              //       });
              //     }
              //   })
              //   .catch((e) => {
              //     console.log(e);
              //     dispatch({
              //       type: "ERROR",
              //       payload: "Error while adding to favorite",
              //     });
              //   })
              //   .finally(() => setAddingToFav(false));
            }}
          >
            <FavoriteIcon
              fontSize="small"
              sx={{
                color:
                  favourites?.filter(
                    (fav) =>
                      fav?.id?._id === dataX?._id
                  ).length > 0
                    ? "red"
                    : "",
              }}
            />
          </IconButton>

          <Box
            height={"10rem"}
            width={"12rem"}
            bgcolor={"#eee"}
            borderRadius={"15px"}
            padding={"1rem"}
            sx={{
              backgroundImage: `url(${image || MealBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
          >
            {temporaryClosed && (
              <Box
                height={"100%"}
                bgcolor={"black"}
                padding={0}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  opacity: "0.5",
                  position: "absolute",
                }}
              >
                <Typography
                  variant="h5"
                  color={"red"}
                  fontWeight={"bold"}
                  opacity={1}
                  sx={{
                    transform: "rotate(-25deg)",
                    textTransform: "uppercase",
                    top: 70,
                    right: 0,
                    left: 20,
                    bottom: 0,
                    position: "absolute",
                  }}
                >
                  Temporary Closed
                </Typography>
              </Box>
            )}
            {discount?.isRunning ? (
              <>
                {!temporaryClosed && (
                  <Chip
                    label={
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {discount?.percentage}%
                      </Typography>
                    }
                    size="medium"
                    sx={{
                      bgcolor: "paper.main",
                      boxShadow: "0 2px 10px rgba(0,0,0, 9%)",
                    }}
                  />
                )}
              </>
            ) : null}
          </Box>

          <Stack
            onClick={() => {
              return temporaryClosed
                ? null
                : navigate(`/restaurants/${dataX?._id}`);
            }}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
          >
            {!temporaryClosed && <Rating value={4.0} size="small" />}
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, fontSize: "1.2rem" }}
            >
              {dataX?.name}
            </Typography>
            <Stack direction={"row"} gap={".5rem"} alignItems={"center"}>
              <Typography variant="body1" color={"GrayText"}>
                {serviceFee} €
              </Typography>
              <Circle sx={{ fontSize: ".4rem", color: grey[400] }} />
              <Typography variant="body1" color={"GrayText"}>
                Service Fee
              </Typography>
            </Stack>
            {/* {!temporaryClosed && (
              <Chip
                label={
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {deliveryTime || 0} mins
                  </Typography>
                }
                size="medium"
                sx={{
                  bgcolor: "primary.main",
                  color: "#fff",
                  boxShadow: "0 2px 10px rgba(0,0,0, 9%)",
                  mt: "1rem",
                }}
              />
            )} */}
          </Stack>
        </Box>
      )}

      {view === "grid" && (
        <Box
          padding={"1rem"}
          borderRadius={"20px"}
          border={`1px solid ${grey[300]}`}
          sx={{
            transition: "all .3s ease-in",
            ":hover": {
              cursor: temporaryClosed ? "not-allowed" : "pointer",
              border: (theme) => `1px solid ${theme.palette.secondary.main}`,
            },
          }}
        >
          <Box
            height={"12rem"}
            bgcolor={"#eee"}
            borderRadius={"15px"}
            mb={3}
            padding={"1rem"}
            sx={{
              backgroundImage: `url(${image || MealBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
          >
            {dataX?.workingDays?.length <= 0 && (
              <Box
                height={"100%"}
                bgcolor={"black"}
                padding={0}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  opacity: "0.5",
                  position: "absolute",
                }}
              >
                <Typography
                  variant="h5"
                  color={"red"}
                  fontWeight={"bold"}
                  sx={{
                    transform: "rotate(-25deg)",
                    textTransform: "uppercase",
                    top: 70,
                    right: 0,
                    left: 20,
                    bottom: 0,
                    position: "absolute",
                  }}
                >
                  Temporary Closed
                </Typography>
              </Box>
            )}
            {discount?.isRunning ? (
              <>
                {!temporaryClosed && (
                  <Chip
                    label={
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {discount?.percentage}%
                      </Typography>
                    }
                    size="medium"
                    sx={{
                      bgcolor: "paper.main",
                      boxShadow: "0 2px 10px rgba(0,0,0, 9%)",
                    }}
                  />
                )}
              </>
            ) : null}

            <IconButton
              size="medium"
              sx={{
                position: "absolute",
                right: "5%",
                top: "5%",
                bgcolor: "#fff",
                cursor: addingToFav ? "wait" : "pointer",
              }}
              onClick={async () => {
                if (!currentUser) {
                  return dispatch({
                    type: "ERROR",
                    payload: "Kindly Login to add to your favorites",
                  });
                }
                setAddingToFav(true);
                const favFound = await favourites.find(
                  (f) =>
                    f?.id?._id === id
                );
                const op = favFound ? "remove" : "add";
                userAccountAPI
                  .favoriteOp({
                    item: id,
                    itemModel: "Restaurant",
                    op,
                  })
                  .then((response) => {
                    if (response.success) {
                      dispatch({ type: "ADD TO FAVORITE" });
                      let newFav = response?.data?.map((d)=>({
                        id: d?.id?._id,
                        docModel: d?.docModel
                      }))
                      dispatch({
                        type: "UPDATE_USER",
                        payload: { ...currentUser, favourites: newFav},
                      });
                      dispatch({ type: "SUCCESS", payload: response?.message });
                    } else {
                      dispatch({
                        type: "ERROR",
                        payload: "Error while adding to favorite",
                      });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    dispatch({
                      type: "ERROR",
                      payload: "Error while adding to favorite",
                    });
                  })
                  .finally(() => setAddingToFav(false));
              }}
            >
              <FavoriteIcon
                fontSize="small"
                sx={{
                  color:
                    favourites?.filter(
                      (fav) =>
                        fav?.id?._id === dataX?._id
                    ).length > 0
                      ? "red"
                      : "",
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Stack
              onClick={() => {
                return temporaryClosed
                  ? null
                  : navigate(`/restaurants/${dataX?._id}`);
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontSize: "1.2rem" }}
              >
                {dataX?.name}
              </Typography>
              <Stack direction={"row"} gap={".5rem"} alignItems={"center"}>
                <Typography variant="body1" color={"GrayText"}>
                  {serviceFee} €
                </Typography>
                <Circle sx={{ fontSize: ".4rem", color: grey[400] }} />
                <Typography variant="body1" color={"GrayText"}>
                  Service Fee
                </Typography>
              </Stack>
              {/* <br />
              {workingScheduleRemapped.map((d, index) => (
                <Stack
                  key={index}
                  direction={"row"}
                  gap={".8rem"}
                  alignItems={"center"}
                >
                  <Typography variant="body2" color={"GrayText"}>
                    {d?.day?.substring(0, 3)}
                  </Typography>
                  <Circle sx={{ fontSize: ".4rem", color: grey[400] }} />
                  <Box display='flex' flexDirection="column">
                    {d?.hours.map((s, index) => (
                      <Typography
                        key={index + "01"}
                        variant="body2"
                        color={"GrayText"}
                      >
                        {s}
                      </Typography>
                    ))}
                  </Box>
                </Stack>
              ))} */}
              <br />
            </Stack>
            <Stack direction={"row"} gap={".2rem"} alignItems={"center"}>
              <Star color="secondary" sx={{ fontSize: "1.1rem" }} />
              <Typography>{rating?.toFixed(1)}</Typography>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RestaurantItem;