import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/Header/PageHeader";
import Logo from "../../../assets/clickso.svg";
import {
  Box,
  Card,
  CardContent,
  Typography,
  styled,
  TextField,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  Link,
  TableFooter,
  Grid,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import StripeApp from "../../../stripe/index.stripe";
import {
  initiatePayment,
  restaurantAPI,
  userAccountAPI,
} from "../../../services/services";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import "../../../stripe/index.stripe.css";
import "./Checkout.css";

const InputField = styled(TextField)({
  marginBottom: "1.2rem",
});

const Checkout = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [fees, setFees] = useState({ service: 0, delivery: 0 });
  const [load, setLoad] = useState(true);
  const cart = useSelector((state) => state.cart);
  const [items, setItems] = useState([]);
  const [deliveryAddress, setDA] = useState("");
  const [deliveryActive, setDeliveryActive] = useState(true)
  const [deliveryMode, setDeliveryMode] = useState("pick up");
  const [processingPayment, setProcessingPayment] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    restaurantAPI.getById(cart?.items[0]?.restaurant).then((res) => {
      setLoad(true)
      setFees((prev) => {
        return {
          ...prev,
          service: res?.data?.waiver?.active
            ? Number(state?.cartTotal) >= res?.data?.waiver?.minOrderAmount
              ? 0
              : res?.data?.service?.fee
            : res?.data?.service?.fee,
          delivery: deliveryMode === "delivery" ? res?.data?.delivery?.fee : 0,
        };
      });
      console.log(res)
      setDeliveryActive(res?.data?.delivery?.active)
      setLoad(false)
    });
    const cartRemapped = cart?.items;
    // console.log(cartRemapped)

    setItems(cartRemapped);
  }, [cart, deliveryMode]);

  useEffect(() => {}, []);

  const changeDeliveryMode = (e) => {
    setDeliveryMode(e.target.value);
  };

  const onOrderSubmit = async (e) => {
    e.preventDefault();
    setProcessingPayment(true);
    const remappedToOrder = cart?.items?.map((item) => {
      console.log(item);
      const label =
        Object.keys(item).indexOf("userSelections") !== -1 &&
        Object.keys(item.userSelections).length > 0
          ? "comboMeal"
          : "menuItem";

      let newBody = {
        [label]: item?._id,
        quantity: item?.quantity,
      };

      if (label === "comboMeal") {
        newBody["userSelections"] = item?.userSelections;
      }

      return newBody;
    });

    let newPayload = {
      items: remappedToOrder,
      total:
        Number(state?.cartTotal) +
        Number(fees?.service) +
        Number(fees?.delivery),
      restaurant: cart?.items[0]?.restaurant,
      deliveryMode
    }

    if (deliveryMode === 'delivery') {
      newPayload['deliveryAddress'] = deliveryAddress
    } 

    try {
      dispatch({
        type: "STORE ORDER TO BE PLACED",
        payload: newPayload,
      });

      const response = await userAccountAPI.initiatePayment({
        amount:
          Number(state?.cartTotal) +
          Number(fees?.service) +
          Number(fees?.delivery),
        currency: "eur",
      });

      if (response.success) {
        setClientSecret(response?.data);
      } else {
        dispatch({
          type: "ERROR",
          payload: "An Error happened while processing for checkout",
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: "ERROR",
        payload:
          "An Error has occurred with the order processing, Kindly try again later",
      });
      setProcessingPayment(false);
    } finally {
    }
  };

  return (
    <>
      {!false && (
        <>
          <PageHeader page={"Checkout"} type={1} link={"/checkout"} />
          <Box
            mt={"3rem"}
            mb={"3rem"}
            width={"100vw"}
            sx={{
              display: "flex",
              flexWrap: "wrap-reverse",
              justifyContent: "space-around",
              alignItems: "start",
            }}
          >
            {clientSecret && (
              <Box
                sx={{
                  width: {
                    xs: "90%",
                    md: "48%",
                  },
                }}
                display="flex"
                flexDirection="column"
                justifyContent={"center"}
              >
                <div className="logo-checkout">
                  <img src={Logo} width={"30%"} alt="logo" />
                </div>
                <StripeApp data={clientSecret}></StripeApp>
              </Box>
            )}

            <Box
              sx={{
                width: {
                  xs: "90%",
                  md: "45%",
                },
                marginBlock: "auto",
              }}
            >
              <Card
                sx={{
                  padding: 5,
                  boxShadow: "0 2px 10px rgba(0,0,0,12%)",
                }}
              >
                <CardContent sx={{ width: "100%", height: "100%", padding: 0 }}>
                  <FormControl sx={{ mb: 3, minWidth: 120 }} size="small">
                    <Select
                      labelId="delivery-mode"
                      id="delivery-mode"
                      value={deliveryMode}
                      disabled={processingPayment}
                      // label="Mode de Livraison"
                      onChange={changeDeliveryMode}
                    >
                      <MenuItem value={"delivery"} disabled={!deliveryActive}>DELIVERY</MenuItem>
                      <MenuItem value={"pick up"}>PICKUP</MenuItem>
                    </Select>
                  </FormControl>

                  {deliveryMode === "delivery" && (
                    <FormControl sx={{ display: "block" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            sx={{ mb: "1rem" }}
                            variant="outlined"
                            fullWidth
                            disabled={processingPayment}
                            value={deliveryAddress}
                            onChange={(e) => setDA(e.target.value)}
                            label="Delivery Address"
                            type={"text"}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  )}
                  <Typography
                    mt={4}
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Order Details
                  </Typography>
                  <TableContainer
                    sx={{ maxHeight: "27rem", overflow: "scroll" }}
                  >
                    <Table
                      stickyHeader
                      sx={{ width: "100%" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Item</TableCell>
                          <TableCell align="right">Unit Price</TableCell>
                          <TableCell align="right">Qty</TableCell>
                          <TableCell align="right">Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((item, index) => (
                          <>
                            <TableRow
                              key={index}
                              sx={{
                                " td, th": {
                                  border: 0,
                                  padding: 1,
                                },
                              }}
                            >
                              <TableCell align="left">{item?.title}</TableCell>
                              <TableCell align="right">
                                {item?.price?.toFixed(2)} €
                              </TableCell>
                              <TableCell align="right">
                                {item?.quantity}
                              </TableCell>
                              <TableCell align="right">
                                {item?.total?.toFixed(2)} €
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                      <TableFooter
                        sx={{
                          position: "sticky",
                          bottom: 0,
                          backgroundColor: "white",
                        }}
                      >
                        <TableRow
                          sx={{
                            margin: 0,
                            "td, th": {
                              border: 0,
                              borderTop: 1,
                              paddingInline: "0.5rem",
                              paddingBottom: 0,
                            },
                          }}
                        >
                          <TableCell>
                            <Typography variant="span">Sub Total</Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="div"
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              {state?.cartTotal}
                              <Typography sx={{ paddingInline: "2px" }}>
                                €
                              </Typography>
                            </Typography>{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            margin: 0,
                            "td, th": {
                              border: 0,
                              paddingBlock: 0,
                              paddingInline: "0.5rem",
                            },
                          }}
                        >
                          <TableCell>
                            <Typography variant="span">Service Fee</Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              variant="div"
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              {fees?.service}
                              <Typography sx={{ paddingInline: "2px" }}>
                                €
                              </Typography>
                            </Typography>{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            margin: 0,
                            "td, th": {
                              border: 0,
                              paddingTop: 0,
                              paddingInline: "0.5rem",
                            },
                          }}
                        >
                          <TableCell>
                            <Typography variant="span">Delivery Fee</Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              variant="div"
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              {fees?.delivery}
                              <Typography sx={{ paddingInline: "2px" }}>
                                €
                              </Typography>
                            </Typography>{" "}
                          </TableCell>
                        </TableRow>

                        <TableRow
                          sx={{
                            "td, th": {
                              border: 0,
                              borderTop: 1,
                              paddingInline: "0.5rem",
                            },
                          }}
                        >
                          <TableCell>
                            {" "}
                            <Typography variant="h5" component="div">
                              Total
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                          <TableCell
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <Typography variant="h5">
                              {Number(state?.cartTotal) +
                                Number(fees?.service) +
                                Number(fees?.delivery)}
                            </Typography>{" "}
                            <Typography
                              variant="h5"
                              sx={{ paddingInline: "2px" }}
                            >
                              €
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                  <Box>
                    <Typography
                      sx={{ fontSize: 12, marginTop: "0.7rem" }}
                      color="text.secondary"
                      gutterBottom
                    >
                      * By completing your purchase you agree to these{" "}
                      <Link color="primary">Terms and conditions</Link>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
              <LoadingButton
                loading={processingPayment}
                disabled={
                  deliveryMode === "delivery" ? !deliveryAddress || load : false
                }
                onClick={onOrderSubmit}
                color="primary"
                size="large"
                fullWidth
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  height: "3.5rem",
                  mt: "1rem",
                }}
              >
                Proceed to payment
              </LoadingButton>
            </Box>
          </Box>
        </>
      )}
      {/* {clientSecret && (
        <>
          <StripeApp data={clientSecret}></StripeApp>
        </>
      )} */}
    </>
  );
};

export default Checkout;
