import axios from "axios";
import {persistor} from "../index"
import environmentToExport from "../config/environment";

const localAPIUrl = `${environmentToExport.baseUrl}/api/v2`;

export const requestHandler = (request) => async (params) => {
  try {
    const response = await request(params);
    return { status: "success", data: response.data.data };
  } catch (e) {
    return { status: "error", data: e?.message };
  }
};

export const requestToApi = (path, methodX, data = null, params={}) => {
  const controller = new AbortController();
  let contentType = "";
  const signal = controller.signal;

  if (!window.navigator.onLine) {
    return;
  }
  if (!!data) {
    if (data instanceof FormData) {
      contentType = "multipart/form-data";
    } else {
      contentType = "application/json";
    }
  }

  const queryString = new URLSearchParams(params).toString();
  const urlWithParams = queryString ? `${localAPIUrl}${path}?${queryString}` : `${localAPIUrl}${path}`;

  let fetchBodyAxios = {
    method: methodX,
    url: urlWithParams,
    headers: {
      "Content-type": contentType,
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    },
    signal,
  };

  if (data) {
    fetchBodyAxios.data = data;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios(fetchBodyAxios);
      resolve(response.data);
    } catch (e) {
      if (e.code === "ECONNABORTED") {
        controller.abort();
        reject('Connection Aborted')
      } else {
        if (axios.isCancel(e)) {
          return console.log("Request Cancelled");
        }
        e.response?.status === 401
          ? (() => {
              persistor.purge();
              localStorage.clear();

              // TO UNCOMMENT
              window.location.href = window.location.origin;
            })()
          : reject(e?.response?.data?.message);
      }
    } finally {
      // Cancel previous request
      // controller.abort();
    }
  });
};

