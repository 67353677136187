import {
  Add,
  Fastfood,
  Search,
  ViewList,
  ViewModule,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ResBg from "../../assets/res_bg.jpg";
import MealItem from "../../components/Meal/Item/MealItem";
import { grey } from "@mui/material/colors";
import MealDetailItem from "../../components/Meal/DetailItem/MealDetailItem";

const Meals = ({ id }) => {
  const [view, setView] = useState();

  const previewMeal = () => {
    setView(1);
  };

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);

  return (
    <Box>
      {/* banner */}
      <Box
        height={"10rem"}
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(0,0,0, 50%), rgba(0,0,0, 60%)), url(${ResBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      />

      {/* content */}
      <Box padding={"6rem 0"}>
        <Container maxWidth="xl">
          <Grid container columnSpacing={5}>
            <Grid item sm={8.5}>
              <Stack direction={"row"} gap={"2rem"} sx={{ mb: "3rem" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="search"
                  placeholder="Search for meals"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={() => {}}
                />
                <Stack
                  direction={"row"}
                  gap={".8rem"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  width={"40%"}
                >
                  <IconButton sx={{ bgcolor: grey[200], borderRadius: "5px" }}>
                    <ViewList />
                  </IconButton>
                  <IconButton sx={{ bgcolor: grey[200], borderRadius: "5px" }}>
                    <ViewModule />
                  </IconButton>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    startIcon={<Add />}
                  >
                    Add a Meal
                  </Button>
                </Stack>
              </Stack>

              <Grid container spacing={3}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((meal, index) => {
                  return (
                    <Grid item sm={4} key={index}>
                      <MealItem onClick={previewMeal} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item sm={3.5}>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid lightgrey",
                  height: "fit-content",
                  padding: "15px",
                  position: "sticky",
                  top: 10,
                }}
              >
                {view ? (
                  <MealDetailItem />
                ) : (
                  <Box
                    height={"30rem"}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Fastfood sx={{ fontSize: "6rem", color: grey[300] }} />
                    <Typography color={"GrayText"} mt={2}>
                      Click to Add a Meal
                    </Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      startIcon={<Add />}
                      sx={{ marginTop: "1rem" }}
                    >
                      Add a Meal
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Meals;
