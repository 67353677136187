import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, TextField, Typography } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { userAccountAPI } from '../../../services/services'
import { useDispatch } from 'react-redux'

const ResetPassword = () => {
  const { token } = useParams()
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()



  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const response = await userAccountAPI.resetPassword(token, {password})

      if(response.success) {
        dispatch({type: 'SUCCESS', payload: response?.message})
        navigate('/login')
      } else {
        dispatch({type: 'ERROR', payload: response?.message})
      }
    } catch (e) {
      dispatch({type: 'ERROR', payload: e})
    } finally {
      setLoading(false)
    }
  } 

  return (
    <Box sx={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Box sx={{
            width: '500px', bgcolor: '#fff', 
            borderRadius: '8px', padding: '3rem', 
            textAlign: 'center', boxShadow: '0 10px 30px rgba(0,0,0, 12%)',
            '& > span': { 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', mb: '1rem'
                }
            }}>
                <Typography variant='h5' sx={{fontWeight: 600, mt: '3rem', mb: '.7rem'}}>Reset Password</Typography>
                <Typography variant='body2' mb='2rem' color={'GrayText'}>Enter your new password for your account.</Typography>

                <TextField 
                  sx={{mb: '1rem'}} 
                  onChange={(e)=>setPassword(e.target.value)}
                  value={password}
                  fullWidth 
                  variant='outlined' 
                  label='New Password' 
                  type={'password'} />


                <TextField 
                  sx={{mb: '1rem'}} 
                  onChange={(e)=>setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  fullWidth 
                  variant='outlined' 
                  label='Confirm Password' 
                  type={'password'} />

                {
                confirmPassword !== '' && password !== confirmPassword 
                && 
                <Typography 
                variant='span' 
                sx={{color: 'red'}}>
                  Passwords don't match
                </Typography>}
              

                <LoadingButton 
                  disableElevation
                  disabled={(password === '' || confirmPassword === '') || password !== confirmPassword}
                  loading={loading}
                  type='button'
                  onClick={onSubmit}
                  size='large' 
                  fullWidth 
                  variant='contained' 
                  color='primary' 
                  sx={{
                    textTransform: 'none', 
                    color: '#fff', 
                    height: '3.5rem', 
                    mb: '2rem'}}>Submit</LoadingButton>
                <Typography textAlign={'center'} mb='2rem'>Return to <Link to={'/login'} style={{textDecoration: 'none', fontWeight: 500, color: '#FF8C00'}}>Login</Link></Typography>
        </Box>
    </Box>
  )
}

export default ResetPassword