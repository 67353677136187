import React from "react";
import './privatePolicies.css'

const PrivatePolicies = () => {
  return (
    <div className="wrap">
      <div className="content">
        <div className="inner-container">
          <h1 className="entry-title">Politiques de Confidentialité</h1>

          <section className="content-editor">
            <p>
              ClickSo est une application de restaurant permettant aux
              utilisateurs de commander des repas auprès de leurs restaurants
              préférés. Nous respectons la vie privée de nos utilisateurs et
              nous engageons à protéger leurs informations personnelles. Cette
              politique de confidentialité explique comment nous collectons,
              utilisons et partageons vos informations personnelles.
            </p>

            <p>
              <strong>Collecte de Données</strong>
            </p>

            <p>
              Nous recueillons les types d’informations personnelles suivants
              auprès de nos utilisateurs :
            </p>

            <ul>
              <li>
                Informations de contact, telles que votre nom, adresse e-mail et
                numéro de téléphone ;
              </li>

              <li>
                Informations démographiques, telles que votre localisation ;
              </li>

              <li>
                Informations de paiement, telles que votre adresse de
                facturation ; nous ne collectons pas les détails de carte de
                crédit car nos paiements sont traités via Google/Apple Pay ;
              </li>

              <li>
                Informations de commande, telles que les aliments que vous
                commandez et les restaurants auprès desquels vous commandez ;
              </li>

              <li>
                Informations sur l’appareil, telles que le type de votre
                appareil, le système d’exploitation et l’adresse IP ;
              </li>

              <li>
                Informations d’utilisation, telles que les pages que vous
                visitez sur notre application et les actions que vous effectuez
                sur notre application.
              </li>
            </ul>

            <p>
              <strong>Utilisation de Vos Informations</strong>
            </p>

            <p>
              Nous utilisons vos informations personnelles aux fins suivantes :
            </p>

            <ul>
              <li>
                Pour vous fournir l’application ClickSo et ses fonctionnalités ;
              </li>

              <li>Pour traiter vos commandes et paiements ;</li>

              <li>
                Pour communiquer avec vous au sujet de vos commandes et de votre
                compte ;
              </li>

              <li>
                Pour améliorer l’application ClickSo et ses fonctionnalités ;
              </li>

              <li>Pour cibler notre marketing et publicité ;</li>

              <li>Pour se conformer à la loi applicable.</li>

              <li></li>
            </ul>

            <p>
              <strong>Partage de Vos Informations :</strong>
            </p>

            <p>
              Nous pouvons partager vos informations personnelles avec les tiers
              suivants :
            </p>

            <ul>
              <li>
                Nos processeurs de paiement, qui ont besoin de vos informations
                de paiement pour traiter vos commandes ;
              </li>

              <li>
                Nos partenaires marketing, qui nous aident à cibler notre
                marketing et publicité ;
              </li>

              <li>
                D’autres tiers (application web de clics), selon le nécessaire
                pour vous fournir l’application ClickSo et ses fonctionnalités
                ou pour se conformer à la loi applicable.
              </li>
            </ul>

            <p>
              <strong>Vos Droits :</strong>
            </p>

            <p>
              Vous avez les droits suivants en ce qui concerne vos informations
              personnelles :
            </p>

            <ul>
              <li>Le droit d’accéder à vos informations personnelles ;</li>

              <li>
                Le droit de corriger toute information personnelle inexacte ou
                incomplète ;
              </li>

              <li>Le droit de supprimer vos informations personnelles ;</li>

              <li>
                Le droit de s’opposer au traitement de vos informations
                personnelles ;
              </li>

              <li>
                Le droit de retirer votre consentement au traitement de vos
                informations personnelles ;
              </li>

              <li>
                Le droit de restreindre le traitement de vos informations
                personnelles ;
              </li>

              <li>Le droit à la portabilité des données.</li>
            </ul>

            <p>
              Vous pouvez exercer vos droits en nous contactant à{" "}
              <a>support@clickso.fr</a>
            </p>

            <p>
              <strong>Sécurité des Données :</strong>
            </p>

            <p>
              Nous prenons la sécurité de vos informations personnelles au
              sérieux. Nous utilisons une variété de mesures de sécurité pour
              protéger vos informations personnelles contre l’accès non
              autorisé, l’utilisation, la divulgation, l’altération ou la
              destruction.
            </p>

            <p>
              <strong>
                Modifications de Cette Politique de Confidentialité
              </strong>
            </p>

            <p>
              Nous pouvons mettre à jour cette politique de confidentialité de
              temps à autre. Si nous apportons des modifications importantes à
              cette politique de confidentialité, nous vous en informerons par
              e-mail ou via un avis bien visible sur notre application.
            </p>

            <p>
              <strong>Contactez-Nous</strong>
            </p>

            <p>
              Si vous avez des questions sur cette politique de confidentialité,
              veuillez nous contacter à <a>support@clickso.fr</a>
            </p>
          </section>
          <div className="container">
            {" "}
            <div className="row">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivatePolicies;
