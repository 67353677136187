import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  CardActionArea,
} from "@mui/material";

function DishCard({ dish }) {
  return (
    <Card
      sx={{
        maxWidth: '80%',
        marginInline: 'auto',
        boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 16px 32px 0 rgba(0,0,0,0.2)",
        },
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="200"
          image={dish.image}
          alt={dish.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {dish.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {dish.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function DishGrid() {
  const dishes = [
    {
      name: "Burger Special",
      description: "Juicy burger with extra cheese and special sauce.",
      image: "https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcRRto3IlY56MlAIOAvXHvPEVxBDVzG1uz1zULEBYdJ-I4Aa-xOyPEVvv7fmIjLnxaOz",
    },
    {
      name: "Classic Pizza",
      description: "Thin crust pizza with fresh toppings.",
      image: "https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcRRto3IlY56MlAIOAvXHvPEVxBDVzG1uz1zULEBYdJ-I4Aa-xOyPEVvv7fmIjLnxaOz",
    },
    {
      name: "Spaghetti Bolognese",
      description: "Rich and flavorful spaghetti with a hearty meat sauce.",
      image: "https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcRRto3IlY56MlAIOAvXHvPEVxBDVzG1uz1zULEBYdJ-I4Aa-xOyPEVvv7fmIjLnxaOz",
    },
    // Add more dishes as needed
  ];

  return (
    <Grid container spacing={4}>
      {dishes.map((dish, index) => (
        <Grid item key={index} xs={12} sm={6} md={4}>
          <DishCard dish={dish} />
        </Grid>
      ))}
    </Grid>
  );
}



export default DishGrid;
