import "./App.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { Navigate, Route, Routes } from "react-router-dom";
import PublicRoute from "./components/Routes/Public/PublicRoute";
import Homepage from "./layout/user/Homepage/Homepage";
import Login from "./layout/auth/Login";
import Register from "./layout/auth/Register";
import { connect } from "react-redux";
import Modal from "./components/Modal/Modal";
import ForgotPassword from "./layout/auth/ForgotPassword";
import Checkout from "./layout/user/Checkout/Checkout";
import RestaurantDetail from "./layout/user/Restaurant/Details/RestaurantDetail";
import Restaurants from "./layout/user/Restaurant/Restaurants";
import PrivateRoute from "./components/Routes/Private/PrivateRoute";
import Profile from "./layout/user/Profile";
import Orders from "./layout/user/Orders";
import ResOrders from "./layout/store/Orders";
import Favorites from "./layout/user/Favorites";
import Dashboard from "./layout/store/Dashboard";
import Meals from "./layout/store/Meals";
import Settings from "./layout/store/Settings";
import RestaurantRoute from "./components/Restaurant/Route/RestaurantRoute";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import VerifyAccount from "./layout/auth/verifyAccount";
import ResultsPage from "./stripe/ResultsPage";
import Notifications from "./components/Notifications/index.notifications";
import PrivatePolicies from "./layout/auth/PrivatePolicies/privatePolicies.main";
import "./css/animations.css";
import ResetPassword from "./layout/auth/ResetPassword/resetPassword.main";
import AboutUs from "./layout/user/About Us/aboutUs.main";
import ContactUs from "./layout/user/Contact Us/contactUs.main";
const App = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const resetModalOnRefresh = () => dispatch({ type: "RESET_MODAL" });
    resetModalOnRefresh();
  }, [dispatch]);

  return (
    <>
      {props.modal && <Modal />}
      <ThemeProvider theme={theme}>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/account/verification" element={<VerifyAccount />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/privacy-policies" element={<PrivatePolicies />} />
            <Route path="/about-us" element={<AboutUs></AboutUs>}></Route>
            <Route path="/contact-us" element={<ContactUs></ContactUs>}></Route>
            <Route path="/restaurants">
              <Route path="" element={<Restaurants />} />
              <Route path=":slug" element={<RestaurantDetail />} />
            </Route>
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/results" element={<ResultsPage />}></Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/account/me" element={<Profile />} />
            <Route path="/account/orders" element={<Orders />} />
            <Route path="/account/favorites" element={<Favorites />} />
            <Route
              path="/account/notifications"
              element={<Notifications />}
            ></Route>
          </Route>
          <Route element={<RestaurantRoute />}>
            <Route path="/res/dashboard" element={<Dashboard />} />
            <Route path="/res/meals" element={<Meals />} />
            <Route path="/res/orders" element={<ResOrders />} />
            <Route path="/res/settings" element={<Settings />} />
          </Route>
          <Route path="*" element={<Navigate to='/login' />} />
        </Routes>
      </ThemeProvider>
    </>
  );
};

const mapStateToprops = (state) => {
  return state;
};

export default connect(mapStateToprops, {})(App);
