import { Add, Remove } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

const Counter = ({ count, size, add, remove }) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      width={"fit-content"}
      gap={"4px"}
      sx={{
        border: "1px solid lightgrey",
        padding: "2px 4px",
        borderRadius: "5px",
      }}
    >
      <IconButton size="small" onClick={remove} sx={{ borderRadius: "4px" }}>
        <Remove sx={{ fontSize: size === "small" ? ".9rem" : null }} />
      </IconButton>
      <Box sx={{}}>
        <Typography variant={size === "small" ? "body2" : "body1"}>
          {count}
        </Typography>
      </Box>
      <IconButton 
        onClick={add} 
        size="small" 
        sx={{ borderRadius: "4px" }}>
        <Add sx={{ fontSize: size === "small" ? ".9rem" : null }} />
      </IconButton>
    </Stack>
  );
};

export default Counter;
