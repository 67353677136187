import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Popover,
  Slide,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import Logo from "../../../assets/clickso.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Add,
  Close,
  Dashboard,
  Euro,
  Fastfood,
  Notifications,
  ReceiptLong,
  Search,
  Settings,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import CartItem from "../../Cart/Item/CartItem";
import { connect, useSelector } from "react-redux";
import { signoutUser } from "../../../services/services";

const NavLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.main,
  ":hover": {
    color: theme.palette.secondary.main,
  },
}));

const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "2rem",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResNavbar = (props) => {
  const { signoutUser } = props;
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);
  const [open, setOpen] = useState(false);
  const [cart, setCart] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const path = useLocation().pathname.split("/")[2];

  // Popover states
  const open2 = Boolean(anchorEl);

  const toCheckout = () => {
    setCart(false);
    navigate("/checkout");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: (theme) => `1px solid ${grey[300]}`,
          padding: "10px 0",
        }}
      >
        <Container maxWidth="xl">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box flex={1} justifyContent={"flex-start"} textAlign={"left"}>
              <Link to={"/"}>
                <img
                  src={Logo}
                  alt="logo"
                  width={"15%"}
                  style={{ cursor: "pointer" }}
                />
              </Link>
            </Box>
            <FlexBox flex={1.5} justifyContent={"center"}>
              <Link
                to={"/res/dashboard"}
                style={{
                  textDecoration: "none",
                  color: path === "dashboard" ? "#D0202A" : "#000",
                }}
              >
                <Stack direction={"row"} gap={"10px"}>
                  <Dashboard />
                  <Typography>Dashboard</Typography>
                </Stack>
              </Link>
              <Link
                to={"/res/orders"}
                style={{
                  textDecoration: "none",
                  color: path === "orders" ? "#D0202A" : "#000",
                }}
              >
                <Stack direction={"row"} gap={"10px"}>
                  <ReceiptLong />
                  <Typography>My Orders</Typography>
                </Stack>
              </Link>
              <Link
                to={"/res/meals"}
                style={{
                  textDecoration: "none",
                  color: path === "meals" ? "#D0202A" : "#000",
                }}
              >
                <Stack direction={"row"} gap={"10px"}>
                  <Fastfood />
                  <Typography>Meals</Typography>
                </Stack>
              </Link>
              <Link
                to={"/res/settings"}
                style={{
                  textDecoration: "none",
                  color: path === "settings" ? "#D0202A" : "#000",
                }}
              >
                <Stack direction={"row"} gap={"10px"}>
                  <Settings />
                  <Typography>Settings</Typography>
                </Stack>
              </Link>
            </FlexBox>

            <FlexBox flex={1} justifyContent={"flex-end"} sx={{ gap: "1rem" }}>
              {/* <Button variant='contained' color='secondary' disableElevation sx={{textTransform: 'none'}} startIcon={<Add fontSize='small' />}>Add Meal</Button> */}
              <Stack direction={"row"} alignItems={"center"}>
                <Euro sx={{ fontSize: "1.1rem" }} />
                <Typography variant="h6" sx={{ fontSize: "1.1rem" }}>
                  0.00
                </Typography>
              </Stack>
              <Badge
                badgeContent={0}
                variant="standard"
                color="secondary"
                showZero
                overlap="circular"
              >
                <IconButton
                  sx={{ border: "1px solid lightgrey", color: "text.main" }}
                >
                  <Notifications fontSize="medium" />
                </IconButton>
              </Badge>
              <Avatar
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{ bgcolor: "primary.main", cursor: "pointer" }}
              >
                {currentUser?.username.charAt(0)?.toUpperCase()}
              </Avatar>
            </FlexBox>
          </Stack>
        </Container>
      </Box>

      {/* SEARCH BAR */}
      <Dialog
        open={open}
        fullScreen
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                background: "#fff",
              },
            }}
            fullWidth
            variant="outlined"
            placeholder="Dishes, restaurants, users"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {" "}
                  <IconButton onClick={() => setOpen(false)}>
                    <Close fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Typography
            sx={{ textAlign: "center", marginTop: "1rem" }}
            variant="body2"
            color="textSecondary"
          >
            Start typing to find what you are looking for
          </Typography>

          {/* SEARCH RESULTS */}
          <Box></Box>
        </DialogContent>
      </Dialog>

      {/* Add A Meal */}
      <Drawer
        anchor="right"
        open={cart}
        onClose={() => {
          setCart(false);
        }}
      >
        <Box width={"20rem"} height={"100%"} padding={"2rem"}>
          <Typography variant="h6" sx={{ fontSize: "1.3rem" }} gutterBottom>
            Food Cart(0)
          </Typography>
          <Divider />
          <CartItem />
          <CartItem />
        </Box>
        <Box
          sx={{
            padding: "1.5rem 2rem",
            bgcolor: "#fff",
            display: "flex",
            gap: "5rem",
            position: "sticky",
            bottom: 0,
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: `1px solid ${grey[200]} `,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={toCheckout}
            disableElevation
            sx={{ height: "3rem" }}
            fullWidth
          >
            CheckOut - € 455.90
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

const mapStateToprops = (state) => state;

export default connect(mapStateToprops, { signoutUser })(ResNavbar);
