import {
  AccountCircle,
  Business,
  Email,
  Map,
  Person,
  Phone,
  Place,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useMemo, useReducer, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { encryptData, userAccountAPI } from "../../services/services";
import { countryPhoneCodes } from "../../constant";

const Register = (props) => {
  const { registerUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userType, setUserType] = useState("buyer");
  const [page, setPage] = useState(1);
  const franceCode = useMemo(
    () =>
      countryPhoneCodes.filter((pc) => pc?.country.toLowerCase() === "france"),
    [countryPhoneCodes]
  );
  const [selectedCountryPhoneCode, setSelectedCountryPhoneCode] = useState(
    `+${franceCode[0].code}`
  ); // TODO set default to France

  const userState = {
    email: "",
    phone: "",
    username: "",
    password: "",
    confirmPassword: "",
  };

  const userReducer = (state, action) => {
    switch (action.type) {
      case "EMAIL":
        return { ...state, email: action.payload };
      case "PHONE":
        return { ...state, phone: action.payload };
      case "USERNAME":
        return { ...state, username: action.payload };
      case "PASSWORD":
        return { ...state, password: action.payload };
      case "CONFIRMPASSWORD":
        return { ...state, confirmPassword: action.payload };
      case "RESET":
        return userState;
      default:
        break;
    }
  };

  const [userInput, userDispatch] = useReducer(userReducer, userState);

  const onFormSubmit = async () => {
    if (userType === "buyer") {
      if (userInput.username === "") {
        dispatch({
          type: "ERROR",
          payload: "Invalid. Please provide username",
        });
        return;
      }
      if (
        userInput.email === "" ||
        !userInput.email.includes("@") ||
        !userInput.email.includes(".com")
      ) {
        dispatch({ type: "ERROR", payload: "Invalid email address" });
        return;
      }
      if (userInput.phone === "") {
        dispatch({ type: "ERROR", payload: "Invalid phone number" });
        return;
      }

      if (userInput.password !== userInput.confirmPassword) {
        dispatch({ type: "ERROR", payload: "Passwords don't match" });
        return;
      }
      // API Connect //

      setLoad(true);

      const data = {
        username: userInput.username,
        email: userInput.email,
        phone: selectedCountryPhoneCode + userInput.phone,
        password: userInput.password,
      };
      const response = await userAccountAPI.register(data).catch((e) => e);

      if (response?.success) {
        const encryptedData = encryptData(JSON.stringify(data.details));
        localStorage.setItem("xyz", encryptedData);
        dispatch({ type: "SUCCESS", payload: "User successfully registered" });
        // navigate("/account/verification?imperio=" + encryptedData);
        navigate("/login");

        setLoad(false);
      } else {
        console.log(response);
        dispatch({ type: "ERROR", payload: response || response?.message });
        setLoad(false);
      }
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "480px",
          bgcolor: "#fff",
          borderRadius: "8px",
          padding: {
            xs: "1rem",
            md: "4rem",
          },
          textAlign: "center",
          boxShadow: "0 10px 30px rgba(0,0,0, 12%)",
          "& > span": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "1rem",
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            mt: "0rem",
            mb: "0.4rem",
            fontSize: {
              xs: "1.2rem",
              sm: "1.8rem",
            },
          }}
        >
          {/* Create An Account */}
          Créer un compte
        </Typography>
        <Typography variant="body2" mb="2rem" color={"GrayText"}>
          {/* Please enter your details below to register */}
          Veuillez fournir vos details pour la création de votre compte
        </Typography>

        {userType === "buyer" && (
          <>
            <TextField
              id="username"
              sx={{ mb: "1rem" }}
              value={userInput.username}
              fullWidth
              variant="outlined"
              // label="Username"
              label="Nom d'utilisateur"
              type={"text"}
              onChange={(e) =>
                userDispatch({ type: "USERNAME", payload: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Person fontSize="small" sx={{ color: grey[400] }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ mb: "1rem" }}
              id="email"
              value={userInput.email}
              fullWidth
              variant="outlined"
              label="Email"
              type={"email"}
              onChange={(e) =>
                userDispatch({ type: "EMAIL", payload: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Email fontSize="small" sx={{ color: grey[400] }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="phone"
              sx={{
                mb: "1rem",
                "& .MuiInputBase-root": {
                  padding: 0,
                },
                "& .MuiInputBase-input:nth-of-type(2)": {
                  marginLeft: "0.7rem",
                },
              }}
              value={userInput.phone}
              fullWidth
              variant="outlined"
              label="Phone"
              type={"tel"}
              onChange={(e) =>
                userDispatch({ type: "PHONE", payload: e.target.value })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Select
                      labelId="countryPhoneCode"
                      id="countryPhoneCode"
                      value={selectedCountryPhoneCode}
                      label="Country Code"
                      onChange={(e) =>
                        setSelectedCountryPhoneCode(e.target.value)
                      }
                    >
                      {countryPhoneCodes.map((cpc, index) => (
                        <MenuItem key={index} value={`+${cpc?.code}`}>
                          {cpc?.country} {`(+${cpc?.code})`}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Phone fontSize="small" sx={{ color: grey[400] }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="password"
              sx={{ mb: "1rem" }}
              fullWidth
              variant="outlined"
              value={userInput.password}
              label="Mot de Passe"
              type={show ? "text" : "password"}
              onChange={(e) =>
                userDispatch({ type: "PASSWORD", payload: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {show ? (
                      <IconButton onClick={() => setShow(false)}>
                        {" "}
                        <Visibility
                          fontSize="small"
                          sx={{ color: grey[400] }}
                        />{" "}
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setShow(true)}>
                        {" "}
                        <VisibilityOff
                          fontSize="small"
                          sx={{ color: grey[400] }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="confirmPassword"
              sx={{ mb: "1rem" }}
              fullWidth
              variant="outlined"
              value={userInput.confirmPassword}
              label="Confirmez Mot de Passe"
              type={show ? "text" : "password"}
              onChange={(e) =>
                userDispatch({
                  type: "CONFIRMPASSWORD",
                  payload: e.target.value,
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showConfirmPassword ? (
                      <IconButton onClick={() => setShowConfirmPassword(false)}>
                        {" "}
                        <Visibility
                          fontSize="small"
                          sx={{ color: grey[400] }}
                        />{" "}
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setShowConfirmPassword(true)}>
                        {" "}
                        <VisibilityOff
                          fontSize="small"
                          sx={{ color: grey[400] }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              id="submit"
              loading={load}
              onClick={onFormSubmit}
              disableElevation
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
                color: "#fff",
                height: "3.5rem",
                mb: "2rem",
                background: "#D0202A !impportant",
              }}
            >
              {/* Register */}
              S'enregistrer
            </LoadingButton>
          </>
        )}

        <Typography textAlign={"center"} color={"GrayText"} mb="2rem">
          {/* Already have an account?  */}
          <span style={{ marginInline: "5px" }}>Vous avez déja un compte?</span>
          <Link to={"/login"}>Connectez vous?</Link>
        </Typography>
        <Typography variant="body2" color={"GrayText"} textAlign="center">
          En vous inscrivant, vous acceptez nos{" "}
          <Link to={"/privacy-policies"}>
            Conditions d'utilisation et politique de confidentialité.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

const mapStateToprops = (state) => state;

export default connect(mapStateToprops, {})(Register);
