import React, { useEffect } from "react";
import Footer from "../../Footer/Footer";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { authRequest } from "../../../services/services";
import { connect, useSelector } from "react-redux";
import ResNavBar from "../../Navbar/Restaurant/ResNavBar";

const RestaurantRoute = (props) => {
  const { authRequest } = props;
  const currentUser = useSelector((state) => state.currentUser);
  const path = useLocation().pathname;

  useEffect(() => {
    authRequest();
  }, [authRequest]);

  if (!currentUser) {
    return <Navigate to={"/"} />;
  }
  if (currentUser?.type === "user") {
    return <Navigate to={"/account/me"} />;
  }

  return (
    <>
      <ResNavBar />
      <Outlet />

      {path === "/login" || path === "/register" ? null : <Footer />}
    </>
  );
};

const mapStateToprops = (state) => state;

export default connect(mapStateToprops, { authRequest })(RestaurantRoute);
